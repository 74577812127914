/* eslint-disable react-hooks/exhaustive-deps */
import BottomCenter, { bottomCenter } from 'components/MapElements/BottomCenter';
import BottomLeft, { bottomLeft } from 'components/MapElements/BottomLeft';
import BottomRight, { bottomRight } from 'components/MapElements/BottomRight';
import TopCenter, { topCenter } from 'components/MapElements/TopCenter';
import TopLeft, { topLeft } from 'components/MapElements/TopLeft';
import TopRight, { topRight } from 'components/MapElements/TopRight';
import WebMapView from 'components/MapView';
import TooltipComponent from 'components/Tooltip';
import MapContext, { MapContextProvider } from 'contexts/mapview-context';
import { initialiseGraphics } from 'graphics';
import { useContext, useState } from 'react';
import { render } from 'react-dom';
import { addGeohubProxyUrls } from 'utils';

const App = () => {
  const context = useContext(MapContext);
  const { view, map } = context;

  const [ready, setReady] = useState(false);

  initialiseGraphics(view);

  // When ready, the map elements are rendered to the DOM
  if (ready) {
    // Setup Geohub basemap with Esri proxy.
    addGeohubProxyUrls();

    // The render function is used to take a React element and render it onto a DOM element.
    // We can use this approach to add our React elements to containers (top left, top right, etc.)
    // These containers can then be added a the view position (e.g. view.ui.add(topLeft, position))
    // The position properties are limited and the top center and bottom center positions are custom
    // We can add html widgets to these custom positions because you are just adding html to a html container
    // We can't add Esri widgets to these html containers, only to the view directly (meaning you need to use the supported positions)

    // render takes the content and the target and render the content to the target
    render(<TopLeft view={view} map={map} />, topLeft);
    render(<TopCenter view={view} map={map} />, topCenter);
    render(<TopRight view={view} map={map} />, topRight);
    render(<BottomLeft view={view} map={map} />, bottomLeft);
    render(<BottomCenter view={view} map={map} />, bottomCenter);
    render(<BottomRight view={view} map={map} />, bottomRight);
  }

  return (
    <MapContextProvider>
      <WebMapView setReady={setReady} />
      <TooltipComponent />
    </MapContextProvider>
  );
};

export default App;