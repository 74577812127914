import { FormControl, InputLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    formControl: {
        width: '100%',
    },
});

const SelectComponent = (props: any) => {
    const classes = useStyles();
    const { label, value, setValue, children } = props;

    return (
        <FormControl variant='outlined' className={classes.formControl} margin='dense'>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                value={value}
                onChange={(event: any) => setValue(event.target.value)}
            >
                {children}
            </Select>
        </FormControl>
    );
};

export default SelectComponent;