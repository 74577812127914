/* eslint-disable react-hooks/exhaustive-deps */
import MenuItem from '@material-ui/core/MenuItem';
import SelectComponent from 'components/Select';
import { Fragment, useEffect } from 'react';
import FindAirport from './FindAirport';
import FindBusStops from './FindBusStops';
import FindFerryRoutes from './FindFerryRoutes';
import FindHospitals from './FindHospitals';
import FindMetroBusInterchange from './FindMetroBusInterchange';
import FindMetroBusRoutes from './FindMetroBusRoutes';
import FindOPALRetailers from './FindOPALRetailers';
import FindRailStations from './FindRailStations';
import FindRegionalCoaches from './FindRegionalCoaches';
import FindRuralContractARoutes from './FindRuralContractARoutes';
import FindRuralLineOfRoute from './FindRuralLineOfRoute';
import FindRuralServiceRegion from './FindRuralServiceRegion';
import FindSchools from './FindSchools';
import FindSydneyTrains from './FindSydneyTrains';

const Attribute = (props: any) => {
  const { layer, setLayer } = props;

  useEffect(() => {
    setLayer('Airport');
  }, []);

  return (
    <Fragment>
      <SelectComponent
        value={layer}
        setValue={setLayer}
        label='Select a Layer'
      >
        <MenuItem value={'Airport'}>Airport</MenuItem>
        <MenuItem value={'All Metro Bus Routes'}>All Metro Bus Routes</MenuItem>
        <MenuItem value={'Bus Stops'}>Bus Stops</MenuItem>
        <MenuItem value={'Ferry Routes'}>Ferry Routes</MenuItem>
        <MenuItem value={'Hospitals'}>Hospitals</MenuItem>
        <MenuItem value={'Metro Bus Interchange'}>Metro Bus Interchange</MenuItem>
        <MenuItem value={'OPAL Retailers'}>OPAL Retailers</MenuItem>
        <MenuItem value={'Rail Stations'}>Rail Stations</MenuItem>
        <MenuItem value={'Regional Coaches'}>Regional Coaches</MenuItem>
        <MenuItem value={'Rural Contract A Routes'}>Rural Contract A Routes</MenuItem>
        <MenuItem value={'Rural Line of Route'}>Rural Line of Route</MenuItem>
        <MenuItem value={'Rural Service Region'}>Rural Service Region</MenuItem>
        <MenuItem value={'Schools'}>Schools</MenuItem>
        <MenuItem value={'Sydney Trains'}>Sydney Trains</MenuItem>
      </SelectComponent>
      {layer === 'Airport' && <FindAirport {...props} />}
      {layer === 'All Metro Bus Routes' && <FindMetroBusRoutes {...props} />}
      {layer === 'Bus Stops' && <FindBusStops {...props} />}
      {layer === 'Ferry Routes' && <FindFerryRoutes {...props} />}
      {layer === 'Hospitals' && <FindHospitals {...props} />}
      {layer === 'Metro Bus Interchange' && <FindMetroBusInterchange {...props} />}
      {layer === 'OPAL Retailers' && <FindOPALRetailers {...props} />}
      {layer === 'Rail Stations' && <FindRailStations {...props} />}
      {layer === 'Regional Coaches' && <FindRegionalCoaches {...props} />}
      {layer === 'Rural Contract A Routes' && <FindRuralContractARoutes {...props} />}
      {layer === 'Rural Line of Route' && <FindRuralLineOfRoute {...props} />}
      {layer === 'Rural Service Region' && <FindRuralServiceRegion {...props} />}
      {layer === 'Schools' && <FindSchools {...props} />}
      {layer === 'Sydney Trains' && <FindSydneyTrains {...props} />}
    </Fragment>
  );
};

export default Attribute;