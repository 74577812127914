import Point from '@arcgis/core/geometry/Point';
import * as projection from '@arcgis/core/geometry/projection';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import { addGraphic, createGraphic } from 'graphics';
import { useEffect, useState } from 'react';
import { ToggleButton } from 'utils';
import ContainerComponent from './Container';
import DraggableWidget from './DraggableWidget';
import ResizableWidget from './ResizableWidget';
import ToggleWidget from './ToggleWidget';

let pointerUpHandle: any = undefined;
let dragEventHandle: any = undefined;

const StreetViewWidget = (props: any) => {
  const { view } = props;
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(320);
  const [location, setLocation] = useState<any>(undefined);
  const [active, setActive] = useState(false);
  const title = 'Street View';

  useEffect(() => {
    projection.load();
  }, []);

  const button: ToggleButton = {
    icon: 'esri-icon-user',
    title: title,
    onActivate: () => {
      view.surface.style.cursor = 'pointer';
      pointerUpHandle = view.on('pointer-up', handlePointerUp);
      dragEventHandle = view.on('drag', handleDrag);
    },
    onDeactivate: () => {
      view.surface.style.cursor = 'default';

      if (pointerUpHandle) {
        pointerUpHandle.remove();
        pointerUpHandle = null;
      }

      if (dragEventHandle) {
        dragEventHandle.remove();
        dragEventHandle = null;
      }

      view.graphics.removeAll();
    },
  };

  const html = location && (
    <DraggableWidget handle='.draggable'>
      <ContainerComponent title={title} onTitleClose={() => setActive(false)}>
        <ResizableWidget
          width={width}
          height={height}
          setWidth={setWidth}
          setHeight={setHeight}
          minConstraints={[400, 320]}
          maxConstraints={[800, 640]}
        >
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <iframe
              title={'StreetView'}
              src={`https://www.google.com/maps/embed/v1/streetview?key=AIzaSyD-RHPJCkxxlkzG3ouo_wMLZVobsYE-2FA&&location=${location}&fov=35`}
              width={width - 6}
              height={height - 55} />
          </div>
        </ResizableWidget>
      </ContainerComponent>
    </DraggableWidget>
  );

  const handlePointerUp = (event: any) => {
    const { x, y } = event;

    const screenPoint = { x, y } as __esri.MapViewScreenPoint;

    const point = view.toMap(screenPoint);

    const convertedPoint = projection.project(point, SpatialReference.WGS84) as Point;

    setLocation(`${convertedPoint.y},${convertedPoint.x}`);

    const graphic = createGraphic(point, '');

    addGraphic(view, graphic, false, true);
  };

  const handleDrag = (event: any) => {
    if (event.action === 'start') {
      if (pointerUpHandle) {
        pointerUpHandle.remove();
        pointerUpHandle = null;
      }
    }

    if (event.action === 'end') {
      if (!pointerUpHandle) {
        pointerUpHandle = view.on('pointer-up', handlePointerUp);
      }
    }
  };

  return (<ToggleWidget {...props} html={html} button={button} active={active} setActive={setActive} />);
};

export default StreetViewWidget;

