/* eslint-disable react-hooks/exhaustive-deps */
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import MapView from '@arcgis/core/views/MapView';
import Point from '@arcgis/core/geometry/Point';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import DetailedResultsWidget from 'components/DetailedResults';
import SelectComponent from 'components/Select';
import ToggleWidget from 'components/ToggleWidget';
import ToolbarContext from 'contexts/toolbar-context';
import { addGraphic, createGraphic } from 'graphics';
import { Fragment, useContext, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { addWidget, createGeometry, removeWidget, ToggleButton, projectToWebMercator, NSWLambert } from 'utils';
import ContainerComponent from '../Container';
import DraggableWidget from '../DraggableWidget';
import Attribute from './Attribute';
import Spatial from './Spatial';
import './Find.css';
import Graphic from '@arcgis/core/Graphic';

const FindWidget = (props: any) => {
  const { view, map, position } = props;
  const [find, setFind] = useState('Attribute');
  const [layer, setLayer] = useState<any>('Airport');
  const [data, setData] = useState<any>([]);

  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState<number>(-1);
  const [results, setResults] = useState<any>([]);
  const [detailedResults, setDetailedResults] = useState<any>([]);
  const [detailed, setDetailed] = useState(false);

  const [widget] = useState<HTMLElement>(document.createElement('div'));
  const [graphic, setGraphic] = useState<any>(undefined);
  const [graphics, setGraphics] = useState<any>([]);

  const [findActive, setFindActive] = useState(false);
  const [resultsActive, setResultsActive] = useState(false);
  const findTitle = 'Find';
  const resultsTitle = 'Results';

  const context = useContext(ToolbarContext);
  const { tools, registerTool, openTools, closeTools } = context;

  useEffect(() => {
    registerTool('find');
    registerTool('results');
  }, []);

  useEffect(() => {
    findActive ? openTools(['find']) : closeTools(['find']);
  }, [findActive]);

  useEffect(() => {
    resultsActive ? openTools(['results']) : closeTools(['results']);
  }, [resultsActive]);

  useEffect(() => {
    if (tools['find']) {
      setFindActive(tools['find'].active);
    }
    if (tools['results']) {
      setResultsActive(tools['results'].active);
    }
  }, [tools]);

  useEffect(() => {
    if (results.length) {
      openTools(['results']);
    }
  }, [results]);

  useEffect(() => {
    if (!data.features) {
      return;
    }

    view.graphics.removeAll();

    const gArray: any = [];

    data.features.forEach((feature: any) => {
      const geometry = createGeometry(feature.geometry, data.geometryType) as any;

      if (geometry) {
        geometry.spatialReference = new SpatialReference({ wkid: data.spatialReference.wkid });
      }

      const table = (
        <TableContainer style={{ width: '100%' }}>
          <Table size='small' style={{ width: '100%' }}>
            <TableBody>
              {data?.fields?.map((field: any) => {
                return (
                  <TableRow key={field.name}>
                    <TableCell>{field.name}</TableCell>
                    <TableCell>{feature?.attributes[field.name]}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );

      const g = createGraphic(geometry, renderToStaticMarkup(table));

      addGraphic(view, g);

      gArray.push(g);
    });

    setGraphics(gArray);

  }, [data]);

  useEffect(() => {
    zoomToFeatures();
  }, [graphics]);

  useEffect(() => {
    render(<DetailedResultsWidget
      detailedResults={detailedResults}
      setSelectedFeatureIndex={setSelectedFeatureIndex}
      onTitleClose={() => { setDetailed(false) }}
    />, widget);
  }, [detailedResults]);

  useEffect(() => {
    if (detailed) {
      addWidget(view, widget, position);
    } else {
      removeWidget(view, widget);
    }

  }, [detailed]);

  useEffect(() => {
    view.graphics.remove(graphic);

    if (selectedFeatureIndex === -1) {
      setGraphic(undefined);
      return;
    }

    const selectedFeature = data.features[selectedFeatureIndex];

    const table = (
      <TableContainer style={{ width: '100%' }}>
        <Table size='small' style={{ width: '100%' }}>
          <TableBody>
            {data?.fields?.map((field: any) => {
              return (
                <TableRow key={field.name}>
                  <TableCell>{field.name}</TableCell>
                  <TableCell>{selectedFeature?.attributes[field.name]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );

    const geometry = createGeometry(selectedFeature.geometry, data.geometryType) as any;

    if (geometry) {
      geometry.spatialReference = new SpatialReference({ wkid: data.spatialReference.wkid });
    }

    const g = createGraphic(geometry, renderToStaticMarkup(table));

    if (g) {
      g.symbol.color.setColor([255, 255, 0]);
    }

    setGraphic(g);

  }, [selectedFeatureIndex]);

  useEffect(() => {
    if (graphic) {
      view.graphics.add(graphic);
    }
  }, [graphic]);

  const zoomToFeature = () => {
    console.log(graphic);
    view.goTo(graphic);
  };

  // TODO: Refactor this the one in Identify?
  const zoomToFeatures = () => {
    if (graphics.length) {
      const type = graphics[0].geometry.type;

      if (type === 'point') {
        // With points, zoom with a zoom level.
        if (graphics.length === 1) {
          const geometry = graphics[0].geometry;
          const centre = projectToWebMercator(new Point({
            x: geometry.x,
            y: geometry.y,
            spatialReference: NSWLambert,
          }));
          view.goTo({ center: centre, zoom: 15 });
        }
      } else {
        // Find total extent of all results.
        const geometries = graphics.map((g: Graphic) => {
          const convertedGeometry = projectToWebMercator(g.geometry);
          return convertedGeometry;
        });
        const totalExtent = geometryEngine.union(geometries);
        view.goTo(totalExtent).then((e: any) => {
          // What happens if zoom is at the minimum?
          view.zoom--;
        });
      }
    }
  };

  const clear = () => {
    setResults([]);
    setDetailedResults([]);
    view.graphics.removeAll();
  };

  const findHTML = (
    <DraggableWidget handle={'.draggable'}>
      <ContainerComponent title={findTitle} onTitleClose={() => {setFindActive(false)}} style={{ width: 400 }}>
        <SelectComponent
          value={find}
          setValue={setFind}
          label='Find'
        >
          <MenuItem value={'Attribute'}>Attribute</MenuItem>
          <MenuItem value={'Spatial'}>Spatial</MenuItem>
        </SelectComponent>
        {find === 'Attribute' && <Attribute
          view={view}
          setResults={setResults}
          setDetailedResults={setDetailedResults}
          setData={setData}
          layer={layer}
          setLayer={setLayer}
        />}
        {find === 'Spatial' && <Spatial
          view={view}
          map={map}
          setResults={setResults}
          setDetailedResults={setDetailedResults}
          setData={setData}
          layer={layer}
          setLayer={setLayer}
        />}
      </ContainerComponent>
    </DraggableWidget>
  );

  const findButton: ToggleButton = {
    icon: 'tp-search-icon',
    title: findTitle,
  };

  const resultsHTML = (
    <Fragment>
      <DraggableWidget handle={'.draggable'}>
        <ContainerComponent title={resultsTitle} onTitleClose={() => setResultsActive(false)}>
          <h1 style={{ fontSize: 16 }}>{(layer) ? `Results for ${layer}` : 'Results'}</h1>
          <p>{results ? results.length : '0'} result(s) found</p>
          <IconButton>
            <SettingsIcon />
          </IconButton>
          <IconButton onClick={() => zoomToFeatures()}>
            <SearchIcon />
          </IconButton>
          <TableContainer style={{ width: '100%', maxHeight: 300, overflow: 'auto' }}>
            <Table size='small' style={{ width: '100%' }}>
              <TableBody>
                {results.map((result: any, index: number) => {
                  return (
                    <TableRow
                      onClick={() => zoomToFeature()}
                      onMouseEnter={() => setSelectedFeatureIndex(index)}
                      onMouseLeave={() => setSelectedFeatureIndex(-1)}
                    >
                      {result.map((value: any) => <TableCell>{value}</TableCell>)}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Button onClick={() => setDetailed(!detailed)}>Detailed Results</Button>
          <Button onClick={() => clear()}>Clear</Button>
        </ContainerComponent>
      </DraggableWidget>
    </Fragment>
  );

  const resultsButton: ToggleButton = {
    icon: 'esri-icon-documentation',
    title: resultsTitle,
    onDeactivate: (view: MapView) => { setDetailed(false) },
  };

  return (
    <Fragment>
      <ToggleWidget {...props} html={findHTML} button={findButton} active={findActive} setActive={setFindActive} />
      <ToggleWidget {...props} html={resultsHTML} button={resultsButton} active={resultsActive} setActive={setResultsActive} />
    </Fragment>
  );
};

export default FindWidget;
