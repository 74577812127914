import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputComponent from 'components/Input';
import SelectComponent from 'components/Select';
import { useState } from 'react';
import { dataURLToPDFTemplate } from 'utils/print';

const PrintTemplate = (props: any) => {
    const { view } = props;
    const [template, setTemplate] = useState('A4 Landscape');
    const [inputTarget, setInputTarget] = useState<any>(undefined);
    const [inputValue, setInputValue] = useState<any>(undefined);

    const printTemplate = () => {
        let pdfWidth = 631.4175;
        let pdfHeight = 446.46;

        if (template === 'A4 Portrait') {
            const temp = pdfWidth;
            pdfWidth = pdfHeight;
            pdfHeight = temp;
        }

        const ratio = pdfHeight / pdfWidth;

        const screenWidth = (view.extent.xmax - view.extent.xmin) / view.resolution;
        const screenHeight = (view.extent.ymax - view.extent.ymin) / view.resolution;

        const area = {
            x: 0,
            y: (screenHeight - screenWidth * ratio) / 2,
            width: screenWidth,
            height: screenWidth * ratio,
        };

        view.takeScreenshot({
            format: 'jpg',
            area: area,
        }).then((screenshot: any) => {
            dataURLToPDFTemplate(screenshot.dataUrl, template, inputValue);
        });
    };

    return (
        <div>
            <SelectComponent
                label='Template'
                value={template}
                setValue={setTemplate}
            >
                <MenuItem value={'A4 Landscape'}>A4 Landscape</MenuItem>
                <MenuItem value={'A4 Portrait'}>A4 Portrait</MenuItem>
                <MenuItem value={'A3 Landscape'}>A3 Landscape</MenuItem>
                <MenuItem value={'A3 Portrait'}>A3 Portrait</MenuItem>
            </SelectComponent>
            <InputComponent
                label='Title'
                inputTarget={inputTarget}
                setInputTarget={setInputTarget}
                setInputValue={setInputValue} />
            <Button onClick={printTemplate}>Print</Button>
        </div>
    );
};

export default PrintTemplate;
