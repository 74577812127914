import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
    textfield: {
        margin: '20px 0 0 0',
    },
});

type Props = {
    label: string;
    placeholder?: string;
    inputTarget: any;
    setInputTarget: any;
    setInputValue: any;
    onEnter?: any;
};

/**
 * Provides a consistent input box
 */
const InputComponent = (props: Props) => {
    const classes = useStyles();
    const { label, placeholder, inputTarget, setInputTarget, setInputValue, onEnter } = props;

    if (!setInputValue) {
        return null;
    }

    return (
        <TextField
            label={label}
            placeholder={placeholder}
            variant='outlined'
            size='small'
            className={classes.textfield}
            onChange={(event: any) => {
                setInputValue(event.target.value);
                setInputTarget(event.target);
            }}
            onKeyUp={(event: any) => {
                if (onEnter && event.key === 'Enter') {
                    onEnter();
                }
            }}
            InputProps={{
                endAdornment: <CloseIcon style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => {
                    setInputValue('');
                    inputTarget.value = '';
                }} />,
            }}
            inputProps={{ style: { fontSize: 12 } }}
            fullWidth
        />
    );
};

export default InputComponent;