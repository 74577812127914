/* eslint-disable react-hooks/exhaustive-deps */
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { Button } from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';

const PrintScreenshot = (props: any) => {
    const { view, map } = props;
    const [sketch, setSketch] = useState<SketchViewModel | undefined>();
    const [graphicsLayer] = useState(new GraphicsLayer());
    const [graphic, setGraphic] = useState<Graphic | undefined>();

    useEffect(() => {
        if (!map) {
            return;
        }

        map.add(graphicsLayer);

        const s = new SketchViewModel({
            view: view,
            layer: graphicsLayer,
        });

        s.on('create', async (event: any) => {
            if (event.state === 'complete') {
                setGraphic(event.graphic);
            }
        });

        setSketch(s);

    }, [map]);

    useEffect(() => {
        if (!sketch) {
            return;
        }

        sketch?.create('rectangle');

        return () => {
            sketch.destroy();
        };
    }, [sketch]);

    const printScreenshot = () => {
        if (!graphic) {
            return;
        }

        const width = graphic.geometry.extent.width / view.resolution;
        const height = graphic.geometry.extent.height / view.resolution;
        const x = (graphic.geometry.extent.xmin - view.extent.xmin) / view.resolution;
        const y = (view.extent.ymax - graphic.geometry.extent.ymax) / view.resolution;

        clearScreenshot();

        view.takeScreenshot({
            format: 'jpg',
            area: {
                x: x,
                y: y,
                width: width,
                height: height,
            },
        }).then((screenshot: any) => {
            const a = document.createElement('a');
            a.href = screenshot.dataUrl;
            a.download = 'transportal_screenshot.jpg';
            a.click();
        });
    };

    const clearScreenshot = () => {
        graphicsLayer.removeAll();
        sketch?.create('rectangle');
        setGraphic(undefined);
    };

    return (
        <div>
            <p>
                To generate a screenshot, click and drag the mouse on the map to highlight the area you wish to capture, then click the bownload button.
            </p>
            {graphic && <Fragment>
                <Button onClick={printScreenshot}>Download</Button>
                <Button onClick={clearScreenshot}>Clear</Button>
            </Fragment>}
        </div>
    );
};

export default PrintScreenshot;
