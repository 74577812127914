
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  toolbarButton: {
    boxShadow: '0 1px 2px rgb(0 0 0 / 30%)',
  },
  mapToolbarActive: {
    backgroundColor: '#e2f1fb',
  }
});

/**
 * Performs an action on button click (no toggle state)
 */
const ActionWidget = (props: any) => {
  const classes = useStyles();
  const { button } = props;

  const { icon, title, onClick } = button;

  // Returns a button to perform the action
  return (
    <div
      role='button'
      key={title}
      title={title}
      tabIndex={0}
      className={`${classes.toolbarButton} esri-home esri-widget--button esri-widget`}
      onClick={onClick}
      onKeyPress={(e) => {
        if (e.key === 'Enter') onClick();
      }}
      aria-label={title}
    >
      <span className={`esri-icon ${icon}`} aria-hidden='true' />
      <span className='esri-icon-font-fallback-text'>{title}</span>
    </div>
  );
};

export default ActionWidget;
