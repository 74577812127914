// App title
export const appTitle = 'Network Overview';

// App-wide extent.
export const initialExtent = {
    // NSW extent, unit based on metres which is based on the topo map.
    xmin: 15693374.037,
    ymin: -4481629.152,
    xmax: 16999177.766,
    ymax: -3365485.861,
    spatialReference: {
        wkid: 102100,
    },
};

// List of layers to be used in layer list widget
export const layers: any = [
    {
        title: 'Customer',
        layerType: 'wms',
        url: 'https://transportal-geoserver.dev.intapps.green.dev.cds.transport.nsw.gov.au/geoserver/TP2/wms',
        identifyURL: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/identify',
        minScale: 50000,
        sublayers: [
            {
                title: 'Ticketing',
                minScale: 50000,
                sublayers: [
                    {
                        name: 'opal_retailers',
                        title: 'OPAL Retailers',
                        visible: false,
                        identifyID: 3,
                        minScale: 50000,
                    },
                    {
                        name: 'myzone_outlets',
                        title: 'MyZone Outlets',
                        visible: false,
                        minScale: 50000,
                    },
                ],
            },
            {
                name: 'hospitals',
                title: 'Hospitals',
                visible: false,
                identifyID: 7,
                minScale: 50000,
            },
            {
                name: 'schools',
                title: 'Schools',
                visible: false,
                identifyID: 8,
                minScale: 50000,
            },
        ],
    },
    {
        title: 'Miscellaneous',
        layerType: 'wms',
        url: 'https://transportal-geoserver.dev.intapps.green.dev.cds.transport.nsw.gov.au/geoserver/TP2/wms',
        identifyURL: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/identify',
        minScale: 100000,
        sublayers: [
            {
                name: 'commuter_car_park',
                title: 'Commuter Cark Park',
                visible: false,
                identifyID: 11,
                minScale: 50000,
            },
            {
                name: 'taxi rank',
                title: 'Taxi Rank',
                visible: false,
                minScale: 50000,
            },
            {
                name: 'airport_tnsw',
                title: 'Airport',
                visible: false,
                identifyID: 14,
                minScale: 100000,
            },
        ],
    },
    {
        title: 'Metro Bus Network',
        layerType: 'wms',
        url: 'https://transportal-geoserver.dev.intapps.green.dev.cds.transport.nsw.gov.au/geoserver/TP2/wms',
        identifyURL: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/identify',
        minScale: 1000000,
        sublayers: [
            {
                name: 'bus stops osd',
                title: 'Bus Stops',
                visible: false,
                identifyID: 16,
                minScale: 50000,
            },
            {
                name: 'bus_interchange',
                title: 'Metro Bus Interchange',
                visible: false,
                identifyID: 17,
                minScale: 1000000,
            },
            {
                name: 'metro_depot_location',
                title: 'Metro Bus Depot',
                visible: false,
                identifyID: 18,
                minScale: 1000000,
            },
            {
                name: 'osd_route_path_dissolve',
                title: 'All Metro Bus Routes',
                visible: false,
                identifyID: 128,
                minScale: 500000,
            },
        ],
    },
    {
        title: 'Rural Bus Network',
        layerType: 'wms',
        url: 'https://transportal-geoserver.dev.intapps.green.dev.cds.transport.nsw.gov.au/geoserver/TP2/wms',
        identifyURL: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/identify',
        sublayers: [
            {
                name: 'regional_terminus',
                title: 'Regional Terminus',
                visible: false,
                identifyID: 96,
                minScale: 5500000,
            },
            {
                name: 'countrylink_coach_route_paths',
                title: 'Regional Coaches',
                visible: false,
                identifyID: 97,
                minScale: 5500000,
            },
            {
                name: 'bus_service_contract',
                title: 'Bus Service Contract Boundaries',
                visible: false,
                identifyID: 98,
            },
        ],
    },
    {
        title: 'Rail Network',
        layerType: 'wms',
        url: 'https://transportal-geoserver.dev.intapps.green.dev.cds.transport.nsw.gov.au/geoserver/TP2/wms',
        identifyURL: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/identify',
        minScale: 5500000,
        sublayers: [
            {
                name: 'railway_stations',
                title: 'Rail Stations',
                visible: false,
                identifyID: 103,
                minScale: 2500000,
            },
            {
                name: 'rail_stations_entrance_exit',
                title: 'Rail Stations Entrance Exit',
                visible: false,
                identifyID: 140,
                minScale: 5500000,
            },
        ],
    },
    {
        title: 'Cycle Network',
        layerType: 'wms',
        url: 'https://transportal-geoserver.dev.intapps.green.dev.cds.transport.nsw.gov.au/geoserver/TP2/wms',
        identifyURL: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/identify',
        minScale: 50000,
        sublayers: [
            {
                name: 'bike_locker',
                title: 'Secure Bike Locker',
                visible: false,
                identifyID: 124,
                minScale: 50000,
            },
        ],
    },
    {
        title: 'Road Assets',
        layerType: 'wms',
        url: 'https://transportal-geoserver.dev.intapps.green.dev.cds.transport.nsw.gov.au/geoserver/TP2/wms',
        identifyURL: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/identify',
        minScale: 50000,
        sublayers: [
            {
                name: 'scats',
                title: 'Traffic Lights',
                visible: false,
                identifyID: 141,
                minScale: 50000,
            },
            {
                name: 'all_bridges',
                title: 'Bridges',
                visible: false,
                identifyID: 142,
                minScale: 50000,
            },
        ],
    },
    {
        title: 'Governance Areas',
        layerType: 'wms',
        url: 'https://transportal-geoserver.dev.intapps.green.dev.cds.transport.nsw.gov.au/geoserver/TP2/wms',
        visibilityMode: 'exclusive',
        sublayers: [
            {
                name: 'suburb',
                title: 'Suburb',
                visible: false,
                minScale: 2500000,
            },
            {
                name: 'stateelectoraldistrict',
                title: 'State Electoral District',
                visible: false,
            },
        ],
    },
    {
        title: 'ABS Statistical Areas',
        layerType: 'wms',
        url: 'https://transportal-geoserver.dev.intapps.green.dev.cds.transport.nsw.gov.au/geoserver/TP2/wms',
        identifyURL: 'https://appln.transport.nsw.gov.au/mapservices/proxy/Common/StatisticalGovernanceAreas/MapServer/identify',
        sublayers: [
            {
                name: 'ucl_enhanced',
                title: 'Urban Centre Localities',
                visible: false,
                identifyID: 6,
                minScale: 1250000,
            },
            {
                name: 'gsccsa_2016',
                title: '2016 Greater Capital City Statistical areas',
                visible: false,
                identifyID: 7,
            },
            {
                name: 'sa1_2016',
                title: '2016 Statistical 1',
                visible: false,
                identifyID: 8,
            },
            {
                name: 'sa2_2016',
                title: '2016 Statistical 2',
                visible: false,
                identifyID: 9,
            },
            {
                name: 'sa3_2016',
                title: '2016 Statistical 3',
                visible: false,
                identifyID: 10,
            },
            {
                name: 'sa4_2016',
                title: '2016 Statistical 4',
                visible: false,
                identifyID: 11,
            },
            {
                name: 'iare_2016',
                title: '2016 Indigenous Area',
                visible: false,
                identifyID: 12,
            },
            {
                name: 'iloc_2016',
                title: '2016 Indigenous Locations',
                visible: false,
                identifyID: 13,
            },
            {
                name: 'ireg_2016',
                title: '2016 Indigenous Regions',
                visible: false,
                identifyID: 14,
            },
            {
                name: 'poa_2016',
                title: '2016 Postal Areas',
                visible: false,
                identifyID: 15,
            },
            {
                name: 'sos_2016',
                title: '2016 Selection of State',
                visible: false,
                identifyID: 16,
            },
            {
                name: 'sosr_2016',
                title: '2016 Section of State Ranges',
                visible: false,
                identifyID: 17,
            },
            {
                name: 'ssc_2016',
                title: '2016 State Suburbs',
                visible: false,
                identifyID: 18,
            },
            {
                name: 'ste_2016',
                title: '2016 State Territory',
                visible: false,
                identifyID: 19,
            },
            {
                name: 'sua_2016',
                title: '2016 Significant Urban Areas',
                visible: false,
                identifyID: 20,
            },
            {
                name: 'ra_2016',
                title: '2016 Remoteness Area',
                visible: false,
                identifyID: 21,
            },
        ],
    },
];

export const query: any = {
    goto: {
        address: {
            endpoints: {
                search: 'https://appln.transport.nsw.gov.au/mapservices/proxy/location/google/address',
            },
            searchField: 'address',
        },
        interchange: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/location/interchange',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/Common/Find/MapServer/4/query',
            },
            searchField: 'interchange',
        },
        intersection: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/location/intersection/main',
            },
        },
        locality: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/location/locality',
                layers: [
                    'https://appln.transport.nsw.gov.au/mapservices/proxy/Common/Find/MapServer/3/query',
                    'https://appln.transport.nsw.gov.au/mapservices/proxy/Common/Find/MapServer/2/query',
                    'https://appln.transport.nsw.gov.au/mapservices/proxy/Common/Find/MapServer/0/query',
                ],
            },
            searchField: 'locality',
        },
        postcode: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/location/postcode',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/Common/Find/MapServer/2/query',
            },
            searchField: 'postcode',
        },
    },
    find: {
        airport: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/14-Airport/airport_query/dynamic',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/14/query',
            },
            searchField: 'AIRPORT_NAME',
            fields: [
                { key: 'AIRPORT_NAME', label: 'Name' },
                { key: 'CITY', label: 'City' },
                { key: 'SCHED_SERVICE', label: 'Scheduled Service' },
                { key: 'ICAO', label: 'ICAO' },
                { key: 'IATA', label: 'IATA' },
                { key: 'MILITARY_CIVIL', label: 'Military Civil' },
            ],
            results: ['AIRPORT_NAME'],
        },
        busStops: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/16-Bus+Stops/bus_stop_query/dynamic',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/16/query',
            },
            searchField: 'TSN',
            fields: [
                { key: 'TSN', label: 'TSN' },
                { key: 'NAME', label: 'Name' },
                { key: 'REGION', label: 'Region' },
                { key: 'STREET', label: 'Street' },
                { key: 'LOCALITY', label: 'Locality' },
            ],
            results: ['TSN'],
        },
        ferryRoutes: {
            endpoints: {
                searches: [
                    'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/110-Ferry+Routes/ferry_operator_query/dynamic',
                    'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/110-Ferry+Routes/ferry_route_query/dynamic',
                ],
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/110/query',
            },
            searchFields: ['OPERATOR', 'ROUTE_LONG_NAME'],
            fields: [
                { key: 'OPERATOR', label: 'Operator' },
                { key: 'ROUTE_LONG_NAME', label: 'Route Name' },
                { key: 'ROUTE_TYPE', label: 'Route Type' },
            ],
            results: ['OPERATOR', ' ROUTE_LONG_NAME'],
        },
        hospitals: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/7-Hospitals/hospital_query/dynamic',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/7/query',
            },
            searchField: 'NAME',
            fields: [
                { key: 'NAME', label: 'Name' },
                { key: 'TYPE', label: 'Type' },
            ],
            results: ['NAME'],
        },
        metroBusInterchange: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/17-Metro+Bus+Interchange/bus_interchange_query/dynamic',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/17/query',
            },
            searchField: 'LOCATION',
            fields: [
                { key: 'LOCATION', label: 'Location' },
            ],
            results: ['LOCATION'],
        },
        metroBusRoutes: {
            endpoints: {
                searches: [
                    'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/19-All+Metro+Bus+Routes/metro_bus_operator_query/static',
                    'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/19-All+Metro+Bus+Routes/metro_bus_route_query/dynamic',
                ],
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/128/query',
            },
            searchFields: ['OPERATOR_NAME', 'ROUTE_VARIANT_NUMBER', 'ROUTEVARIANTTYPEID'],
            fields: [
                { key: 'ROUTE', label: 'Route' },
                { key: 'ROUTE_VARIANT_NUMBER', label: 'Variant No' },
                { key: 'DIRECTIONID', label: 'Direction' },
                { key: 'ROUTEVARIANTTYPEID', label: 'Type' },
                { key: 'OPERATOR_NAME', label: 'Operator Name' },
                { key: 'ROUTE_NAME', label: 'Route Name' },
                { key: 'ROUTE_VARIANT_NAME', label: 'Route Variant Name' },
                { key: 'REGIONNAME', label: 'Region Name' },
                { key: 'CONTRACTID', label: 'Contract Id' },
            ],
            results: ['ROUTE_VARIANT_NUMBER', ' ROUTEVARIANTTYPEID', ' OPERATOR_NAME'],
        },
        opalRetailers: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/3-OPAL+Retailers/opal_query/dynamic',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/3/query',
            },
            searchField: 'SUBURB',
            fields: [
                { key: 'RETAILER_NAME', label: 'Name' },
                { key: 'STREET_ADDRESS', label: 'Address' },
                { key: 'ETS_CATEGORY', label: 'Category' },
                { key: 'STATUS', label: 'Status' },
                { key: 'LAST_UPDATED', label: 'Last Updated' },
                { key: 'PHONE_NO', label: 'Phone' },
                { key: 'RETAILER_ID', label: 'Retailer ID' },
            ],
            results: ['RETAILER_NAME', ' PHONE_NO', ' RETAILER_ID'],
        },
        railStations: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/103-Rail+Stations/railway_station_query/dynamic',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/103/query',
            },
            searchField: 'STATION_NAME',
            fields: [
                { key: 'STATION_NAME', label: 'Station Name' },
                { key: 'RAILWAY_TYPE', label: 'Railway Type' },
                { key: 'TSN', label: 'TSN' },
                { key: 'STATUS', label: 'Status' },
                { key: 'UNDERGROUND', label: 'Underground' },
            ],
            results: ['STATION_NAME'],
        },
        regionalCoaches: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/97-Regional+Coaches/countrylink_coach_query/dynamic',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/97/query',
            },
            searchField: 'ROUTENAME',
            fields: [
                { key: 'ROUTENAME', label: 'Route Name' },
                { key: 'VARIANTNAME', label: 'Variant Name' },
                { key: 'DIRECTION', label: 'Direction' },
                { key: 'ROUTETYPE', label: 'Route Type' },
                { key: 'OPERATOR', label: 'Operator' },
            ],
            results: ['ROUTENAME'],
        },
        ruralContractARoutes: {
            endpoints: {
                searches: [
                    'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/98-Rural+Contract+A+Routes/rural_contracta_query_a/dynamic',
                    'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/98-Rural+Contract+A+Routes/rural_contracta_query_b/dynamic',
                ],
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/98/query',
            },
            searchFields: ['CONTRACT', 'ROUTE'],
            fields: [
                { key: 'ROUTE', label: 'Route' },
                { key: 'VARIANT', label: 'Variant' },
                { key: 'DESCRIPTION', label: 'Name' },
                { key: 'OPERATOR', label: 'Operator' },
                { key: 'CONTRACT', label: 'Contract' },
                { key: 'EFFECTIVEDATE', label: 'Effective Date' },
            ],
            results: ['CONTRACT'],
        },
        ruralLineOfRoute: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/101-Rural+Line+of+Route/rural_line_of_route_query/dynamic',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/101/query',
            },
            searchField: 'CONTRACT',
            fields: [
                { key: 'OPERATOR', label: 'Operator' },
                { key: 'CONTRACT', label: 'Contract' },
                { key: 'DESCRIPTION', label: 'Description' },
                { key: 'REGION_CODE', label: 'Region Code' },
            ],
            results: ['CONTRACT', ' DESCRIPTION'],
        },
        ruralServiceRegion: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/100-Rural+Service+Region/rural_service_area_query/dynamic',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/100/query',
            },
            searchField: 'CONTRACT',
            fields: [
                { key: 'OPERATOR', label: 'Operator' },
                { key: 'CONTRACT', label: 'Contract' },
                { key: 'DESCRIPTION', label: 'Description' },
                { key: 'REGION_CODE', label: 'Region Code' },

            ],
            results: ['CONTRACT', ' DESCRIPTION'],
        },
        schools: {
            endpoints: {
                search: 'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/8-Schools/school_query/dynamic',
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/8/query',
            },
            searchField: 'NAME',
            fields: [
                { key: 'NAME', label: 'Name' },
                { key: 'TYPE', label: 'Type' },
            ],
            results: ['NAME'],
        },
        sydneyTrains: {
            endpoints: {
                searches: [
                    'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/105-Sydney+Trains/sydney_trains_line_query/static',
                    'https://gis.transport.nsw.gov.au/mapviewer/overview/lookup/105-Sydney+Trains/sydney_trains_section_query/static',
                ],
                layer: 'https://appln.transport.nsw.gov.au/mapservices/proxy/NetworkOverview/NetworkOverview/MapServer/129/query',
            },
            searchFields: ['CITYRAIL_NAME', 'LR_NAME'],
            fields: [
                { key: 'CITYRAIL_NAME', label: 'Line Name' },
                { key: 'LR_NAME', label: 'Section Name' },
            ],
            results: ['CITYRAIL_NAME'],
        },
    },
};
