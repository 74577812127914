import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    tooltip: {
        position: 'fixed',
        padding: '20px',
        fontSize: '12px',
        boxShadow: '0 1px 2px rgb(0 0 0 / 30%)',
        color: '#6e6e6e',
        pointerEvents: 'none',
        background: 'white',
        transform: 'translate(-50%, -100%)',
    },
});

/**
 * Retrieved using its id and provides a container for the tooltip content
 */
const TooltipComponent = () => {
    const classes = useStyles();

    return (<div id='tooltip' className={classes.tooltip} />);
};

export default TooltipComponent;
