/* eslint-disable react-hooks/exhaustive-deps */
import * as watchUtils from '@arcgis/core/core/watchUtils';
import Extent from '@arcgis/core/geometry/Extent';
import { Fragment, useEffect } from 'react';
import { ActionButton, popFuture, popHistory, pushFuture, pushHistory } from 'utils';
import ActionWidget from './ActionWidget';

let currentExtent: Extent | null = null;
let ignoreNext = false;

const HistoryManagerWidget = (props: any) => {
  const { view } = props;
  const backTitle = 'Back';
  const forwardTitle = 'Forward';

  useEffect(() => {
    currentExtent = view.extent.clone();

    watchUtils.whenFalse(view, 'stationary', () => {
      if (!view.stationary) {
        watchUtils.whenTrueOnce(view, 'stationary', () => {
          if (!ignoreNext) {
            if (currentExtent) pushHistory(currentExtent);
            currentExtent = view.extent.clone();
          } else {
            ignoreNext = false;
          }
        });
      } else {
        watchUtils.whenFalseOnce(view, 'interacting', () => {
          if (!ignoreNext) {
            if (currentExtent) pushHistory(currentExtent);
            currentExtent = view.extent.clone();
          } else {
            ignoreNext = false;
          }
        });
      }
    });
  }, []);

  /**
   * Will move the map to the previous extent.
   * Current extent will be moved into the 'future' list.
   */
  const goBack = () => {
    if (!view) return;
    // pop extent from history and set it
    const extent = popHistory();
    if (extent) {
      // add current extent to future
      pushFuture(view.extent.clone());
      ignoreNext = true;
      view.goTo(extent);
    }
  };

  /**
   * Will move the map to the next extent
   * Current extent will be moved into the 'history' list.
   */
  const goForwards = () => {
    if (!view) return;
    // pop extent from history and set it
    const extent = popFuture();
    if (extent) {
      // add current extent to history
      pushHistory(view.extent.clone(), true);
      ignoreNext = true;
      view.goTo(extent);
    }
  };

  const backButton: ActionButton =
  {
    icon: 'esri-icon-left',
    title: backTitle,
    onClick: () => goBack(),
  };

  const forwardButton: ActionButton = {
    icon: 'esri-icon-right',
    title: forwardTitle,
    onClick: () => goForwards(),
  };

  return (
    <Fragment>
      <ActionWidget button={backButton} />
      <ActionWidget button={forwardButton} />
    </Fragment >
  );
};

export default HistoryManagerWidget;
