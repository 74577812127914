import { makeStyles } from '@material-ui/core/styles';
import { Children, cloneElement, Fragment } from 'react';

const useStyles = makeStyles({
    toolbarGroup: {
        display: 'grid',
        margin: 0,
        marginLeft: 8,
        '&:first-child': {
            marginLeft: 0,
        },
    },
    toolbarGroupV: {
        display: 'grid',
        margin: 0,
        marginBottom: 10,
        '&:last-child': {
            marginBottom: 0,
        },
    },
});

/**
 * This groups the toolbar buttons
 */
const ToolbarGroup = (props: any) => {
    const classes = useStyles();
    const { view, map, position, children, container } = props;

    return (
        <div
            style={{
                gridAutoFlow: 'column',
                display: 'grid',
            }}
            className={classes.toolbarGroup}
        >
            {Children.map(children, (child: any, index: number) => {
                // Each child is a toolbar button. Here the child is cloned so that the properties can be passed to it
                return (
                    <Fragment key={index}>
                        {cloneElement(child, { view, map, position, container })}
                    </Fragment>);
            })}
        </div>
    );
};

export default ToolbarGroup;