import indigo from '@material-ui/core/colors/indigo';
import purple from '@material-ui/core/colors/purple';
import { createTheme } from '@material-ui/core/styles';

const overviewTheme = createTheme({
  palette: {
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: purple[500],
    },
  },
  props: {
    MuiButton: {
      variant: "contained",
      color: "primary"
    }
  },
});

export default overviewTheme;