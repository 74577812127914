import Point from '@arcgis/core/geometry/Point';
import MenuItem from '@material-ui/core/MenuItem';
import SelectComponent from 'components/Select';
import { addGraphic, createGraphic } from 'graphics';
import { Fragment, useState } from 'react';
import { GDA1994, MGAZone54, MGAZone55, MGAZone56, NSWLambert, WGS1984 } from 'utils';
import InputComponent from '../Input';

const GoToCoordinate = (props: any) => {
  const { view } = props;
  const [sr, setSR] = useState('NSW Lambert');
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const [targetX, setTargetX] = useState<any>(undefined);
  const [targetY, setTargetY] = useState<any>(undefined);

  const srDetails: any = {
    'NSW Lambert': {
      placeholders: { x: '(e.g 9688878.52)', y: '(e.g 4423681.77)' },
      sr: NSWLambert,
    },
    'GDA 1994': {
      placeholders: { x: '(e.g 151.207735)', y: '(e.g -33.868035)' },
      sr: GDA1994,
    },
    'WGS 1984': {
      placeholders: { x: '(e.g 151.207735)', y: '(e.g -33.868035)' },
      sr: WGS1984,
    },
    'MGA Zone 54': {
      placeholders: { x: '(e.g 607758.84)', y: '(e.g 6216403.80)' },
      sr: MGAZone54,
    },
    'MGA Zone 55': {
      placeholders: { x: '(e.g 302105.12)', y: '(e.g 6179831.91)' },
      sr: MGAZone55,
    },
    'MGA Zone 56': {
      placeholders: { x: '(e.g 334222.44)', y: '(e.g 6251030.63)' },
      sr: MGAZone56,
    },
  };

  const updateCoordinate = () => {
    const point = new Point({
      x: x,
      y: y,
      spatialReference: srDetails[sr].sr,
    });

    const graphic = createGraphic(point, `X: ${point.x.toFixed(4)}, Y: ${point.y.toFixed(4)}`);

    addGraphic(view, graphic, true);
  };

  return (
    <Fragment>
      <SelectComponent label={'Coordinate System'} value={sr} setValue={setSR}>
        <MenuItem value={'NSW Lambert'}>NSW Lambert</MenuItem>
        <MenuItem value={'GDA 1994'}>GDA 1994</MenuItem>
        <MenuItem value={'WGS 1984'}>WGS 1984</MenuItem>
        <MenuItem value={'MGA Zone 54'}>MGA Zone 54</MenuItem>
        <MenuItem value={'MGA Zone 55'}>MGA Zone 55</MenuItem>
        <MenuItem value={'MGA Zone 56'}>MGA Zone 56</MenuItem>
      </SelectComponent>
      <InputComponent
        label='X'
        placeholder={srDetails[sr].placeholders.x}
        inputTarget={targetX}
        setInputTarget={setTargetX}
        setInputValue={setX}
        onEnter={updateCoordinate()}
      />
      <InputComponent
        label='Y'
        placeholder={srDetails[sr].placeholders.y}
        inputTarget={targetY}
        setInputTarget={setTargetY}
        setInputValue={setY}
        onEnter={updateCoordinate()}
      />
    </Fragment>
  );
};

export default GoToCoordinate;