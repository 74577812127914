/* eslint-disable react-hooks/exhaustive-deps */
import Graphic from '@arcgis/core/Graphic';
import { query } from 'config';
import { addNSWLambertGraphicFromResponse } from 'graphics';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { request } from 'utils';
import DropDownComponent from '../Dropdown';
import { updateMenuItems, NSWLambert } from 'utils';
import InputComponent from '../Input';

const GoToInterchange = (props: any) => {
  const { view } = props;
  const [menuItems, setMenuItems] = useState<ReactElement | null>(null);
  const [inputTarget, setInputTarget] = useState<any>(undefined);
  const [inputValue, setInputValue] = useState<any>(undefined);
  const queryConfig = query.goto.interchange;

  useEffect(() => {
    if (inputValue) {
      updateMenuItems(queryConfig.endpoints.search, queryConfig.searchField, inputValue, inputTarget, setMenuItems, updateGraphics);
    } else {
      setMenuItems(null);
    }
  }, [inputValue]);

  const onGraphicsAdded = (graphic: Graphic | null) => {
    if (graphic) {
        view.goTo({ center: graphic.geometry, zoom: 15 });
    }
  };

  const updateGraphics = (item: any) => {
    const baseURL = queryConfig.endpoints.layer;
    const value = item[queryConfig.searchField];
    const objectid = item.objectid.split(':')[0];

    const params: any = {
      f: 'json',
      where: `OBJECTID=${objectid}`,
      returnGeometry: true,
      spatialRel: 'esriSpatialRelIntersects',
      siteId: 'overview',
      outSR: NSWLambert,
    };

    request(baseURL, params, (response: any) => addNSWLambertGraphicFromResponse(view, response, value, onGraphicsAdded), undefined, undefined);
  };

  return (
    <Fragment>
      <InputComponent
        label='Interchange Name'
        placeholder='(e.g Lane Cove)'
        inputTarget={inputTarget}
        setInputTarget={setInputTarget}
        setInputValue={setInputValue}
      />
      <DropDownComponent>{menuItems}</DropDownComponent>
    </Fragment>
  );
};

export default GoToInterchange;