/* eslint-disable react-hooks/exhaustive-deps */
import AreaMeasurement2D from '@arcgis/core/widgets/AreaMeasurement2D';
import ToolbarContext from 'contexts/toolbar-context';
import { useContext, useEffect, useState } from 'react';
import { ToggleButton } from 'utils';
import esriContainer from './EsriContainer';
import ToggleWidget from './ToggleWidget';

const AreaWidget = (props: any) => {
  const { view } = props;
  const [active, setActive] = useState(false);
  const title = 'Measure Area';

  const context = useContext(ToolbarContext);
  const { tools, registerTool, openTools, closeTools } = context;

  useEffect(() => {
    registerTool('area');
  }, []);

  useEffect(() => {
    if (active) {
      openTools(['area']);
      closeTools(['measure']);
    } else {
      closeTools(['area']);
    }
  }, [active]);

  useEffect(() => {
    if (tools['area']) {
      setActive(tools['area'].active);
    }
  }, [tools]);

  const [areaWidget] = useState(new AreaMeasurement2D({
    view: view,
    container: esriContainer(title, setActive),
  }));

  useEffect(() => {
    // @ts-ignore
    areaWidget.viewModel.palette = {
      handleWidth: 6,
      handleColor: [128, 128, 128, 0.5],
      pathWidth: 3,
      pathPrimaryColor: [0, 0, 0, 64],
      pathSecondaryColor: [255, 255, 255, 64],
    };

    // @ts-ignore
    areaWidget.viewModel.unitOptions = ['metric', 'square-meters', 'square-kilometers'];
  });

  const button: ToggleButton = {
    icon: 'esri-icon-measure-area',
    title: title,
    onActivate: () => {
      areaWidget.viewModel.start();
    },
    onDeactivate: () => {
      areaWidget.viewModel.clear();
    },
  };

  return (<ToggleWidget {...props} widget={areaWidget} button={button} active={active} setActive={setActive} />);
}

export default AreaWidget;

