/* eslint-disable react-hooks/exhaustive-deps */
import CoordinatesTracker from 'components/CoordinatesTracker';
import { useEffect } from 'react';

export const bottomRight = document.createElement('div');

const BottomRight = (props: any) => {
    const { view } = props;

    // Adds bottom right content to view on first render
    useEffect(() => {
        view.ui.add(bottomRight, 'bottom-right');
    }, []);

    return (
        <CoordinatesTracker />
    );
};

export default BottomRight;