import Search from '@arcgis/core/widgets/Search';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import MapView from '@arcgis/core/views/MapView';
import SearchSource from '@arcgis/core/widgets/Search/SearchSource';
import esriRequest from '@arcgis/core/request';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import Polygon from '@arcgis/core/geometry/Polygon';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import esriContainer, { ContainerTitle } from '../EsriContainer';

const searchConfig = {
    // Common search configs.
    exactMatch: false,
    outFields: ['*'],
    maxResults: 6,
    maxSuggestions: 6,
    maxResultsForAddressesAndRoads: 20, // 20 to be consistent withh the NSW Address API.
    maxSuggestionsForAddressesAndRoads: 20,
    suggestionsEnabled: true,
    minSuggestCharacters: 3,
};

const addressSearchUrl = 'http://gis-dev.transport.nsw.gov.au/mapservices/secure/crashlink/address/search';
const addressSuggestUrl = 'http://gis-dev.transport.nsw.gov.au/mapservices/secure/crashlink/address/suggest';

const suburbsLayer: FeatureLayer = new FeatureLayer({
    url: 'https://gis-dev.transport.nsw.gov.au/mapservices/proxy/services/CLDC/Crashlink/MapServer/7',
});

const lgaLayer: FeatureLayer = new FeatureLayer({
    url: 'https://gis-dev.transport.nsw.gov.au/mapservices/proxy/services/CLDC/Crashlink/MapServer/10',
});

const electoralDistrictsLayer: FeatureLayer = new FeatureLayer({
    url: 'https://gis-dev.transport.nsw.gov.au/mapservices/proxy/services/CLDC/Crashlink/MapServer/6',
});

const streetsLayer: FeatureLayer = new FeatureLayer({
    url: 'https://gis-dev.transport.nsw.gov.au/mapservices/proxy/services/CLDC/Crashlink/MapServer/12',
});

const classifiedRoadsLayer: FeatureLayer = new FeatureLayer({
    url: 'http://gis-dev.transport.nsw.gov.au/mapservices/proxy/services/CLDC/Crashlink/MapServer/3',
});

/**
 * Find an address using the NSW Address POINT API which is wrapped around with a TransPortal map service. See
 * the service itself for implementation details.
 *
 * How to implement custom search:
 * https://developers.arcgis.com/javascript/latest/sample-code/sandbox/index.html?sample=widgets-search-customsource
 *
 * Example of searching an address:
 * http://gis-dev.transport.nsw.gov.au/mapservices/secure/crashlink/address/search?address=9 O'Connell Street, Parramatta NSW
 *
 * @param params The search widget param object.
 * @returns An array of search results. It appears the NSW Address POINT API returns only one result.
 */
const getAddressSearchResults = async (params: any) => {
    console.debug('Address search input params:');
    console.debug(params);

    if (!params.suggestResult.text || params.suggestResult.text.trim() === '') return [] as any[];

    const searchResults: any[] = [];
    const requestParam = `?address=${params.suggestResult.text}`;

    try {
        // Fire off the request to the address search API.
        const results = await esriRequest(addressSearchUrl + requestParam, {
            method: 'auto',
            responseType: 'json',
            headers: {
                'X-Token': 'TBD', // TODO: Secure CLDC services.
            },
        });

        console.debug('Address search returns successfully with the following result:');
        console.debug(results);

        const addressGeometry = results.data.data.geo?.geometry;

        if (addressGeometry) {
            // We have an address in point geometry.
            if (addressGeometry.type.toLowerCase() === 'point') {
                // Create point graphic.
                const graphic = new Graphic({
                    geometry: new Point({
                        x: addressGeometry.coordinates[0],
                        y: addressGeometry.coordinates[1],
                    }),
                    // TODO: currently the symbol is not working.
                    symbol: new SimpleMarkerSymbol({
                        style: 'square',
                        color: 'blue',
                        size: '24px',
                        outline: {
                            color: [255, 255, 0],
                            width: 3,
                        },
                    }),
                    attributes: results.data.data.addressDetails,
                });

                // Assign it an extent so it can be zoomed to automatically.
                const buffer: Polygon = geometryEngine.geodesicBuffer(graphic.geometry, 100, 'meters') as Polygon;

                // Add address point as a search result.
                searchResults.push({
                    extent: buffer,
                    feature: graphic,
                    name: results.data.data.addressDetails.formattedAddress,
                });
            } else {
                // TODO: Is it possible for the API to return a line or polygon?
                console.log(`Address search returns a non-point geometry type of ${addressGeometry.type}`);
            }
        }
    } catch (err) {
        console.warn(`Failed to find the following address: ${params.suggestResult.text}`);
    }

    return searchResults;
};

/**
 * Find address suggestions (predictive search) using the NSW Address POINT API which is wrapped around with a
 * TransPortal map service. See the service itself for implementation details.
 *
 * https://developers.arcgis.com/javascript/latest/api-reference/esri-widgets-Search-SearchSource.html#GetSuggestionsParameters
 *
 * @param params Suggestion parameters.
 *
 * @returns An array of Suggest Result objects.
 */
const getAddressSearchSuggestions = async (params: any) => {
    console.debug(params);

    const requestParam = `?address=${params.suggestTerm}`;

    try {
        // Fire off the request to the address suggestion API.
        const results = await esriRequest(addressSuggestUrl + requestParam, {
            method: 'auto',
            responseType: 'json',
            headers: {
                'X-Token': 'TBD', // TODO: Secure CLDC services.
            },
        });

        console.debug('Address suggestion returns successfully with the following result:');
        console.debug(results);

        // Return the NSW API predictive/suggestion results (if any) in this format:
        // https://developers.arcgis.com/javascript/latest/api-reference/esri-widgets-Search.html#SuggestResult
        const suggestedAddresses: any[] = results.data;
        return results.data.length > 0
            ? suggestedAddresses.map((addressResult) => ({
                key: addressResult.id, // This can also be addressResult.address, doesn't seem to have effect.
                text: addressResult.address, // This seems to be used to sort out-of-box by default.
                sourceIndex: 0, // This is index of the address search as defined for the search widget.
            }))
            : [];
    } catch (err) {
        console.warn(`Failed to find suggestion for following input: ${params.suggestTerm}`);
    }

    return [];
};

const addressSearchSource = new SearchSource({
    // @ts-ignore
    name: 'Address',
    placeholder: 'Type address',
    maxResults: searchConfig.maxResultsForAddressesAndRoads,
    maxSuggestions: searchConfig.maxSuggestionsForAddressesAndRoads,
    suggestionsEnabled: true,
    minSuggestCharacters: searchConfig.minSuggestCharacters, // Note min character is 4 as documented in the NSW API.
    getResults: getAddressSearchResults,
    getSuggestions: getAddressSearchSuggestions,
});

export default function getSearchDetails(mapview: MapView, title: ContainerTitle, setActive: any): Search {
    const searchWidget = new Search({
        view: mapview,
        container: esriContainer(title, setActive),
        allPlaceholder: 'type place name',
        includeDefaultSources: false,
        sources: [
            addressSearchSource,
            {
                // @ts-ignore
                layer: streetsLayer,
                searchFields: ['STREET_SUBURB'],
                displayField: 'STREET_SUBURB',
                exactMatch: searchConfig.exactMatch,
                outFields: searchConfig.outFields,
                name: 'Street',
                placeholder: 'Type Street Name',
                maxResults: searchConfig.maxResultsForAddressesAndRoads,
                maxSuggestions: searchConfig.maxSuggestionsForAddressesAndRoads,
                suggestionsEnabled: searchConfig.suggestionsEnabled,
                minSuggestCharacters: searchConfig.minSuggestCharacters,
                suggestionTemplate: '{STREET}, {SUBURBNAME}',
                resultGraphicEnabled: true,
                resultSymbol: {
                    // @ts-ignore
                    type: 'simple-line', // autocasts as new SimpleLineMarkerSymbol()
                    color: '#EE22AA',
                    width: 4,
                },
            },
            {
                // @ts-ignore
                layer: classifiedRoadsLayer,
                searchFields: ['NE_DESCR'],
                displayField: 'NE_DESCR',
                exactMatch: searchConfig.exactMatch,
                outFields: searchConfig.outFields,
                name: 'Classified Roads',
                placeholder: 'type classified road name',
                maxResults: searchConfig.maxResultsForAddressesAndRoads,
                maxSuggestions: searchConfig.maxSuggestionsForAddressesAndRoads,
                suggestionsEnabled: searchConfig.suggestionsEnabled,
                minSuggestCharacters: searchConfig.minSuggestCharacters,
            },
            {
                // @ts-ignore
                layer: suburbsLayer,
                searchFields: ['SUBURBNAME'],
                displayField: 'SUBURBNAME',
                exactMatch: searchConfig.exactMatch,
                outFields: searchConfig.outFields,
                name: 'Suburb',
                placeholder: 'type suburb name',
                maxResults: searchConfig.maxResults,
                maxSuggestions: searchConfig.maxSuggestions,
                suggestionsEnabled: searchConfig.suggestionsEnabled,
                minSuggestCharacters: searchConfig.minSuggestCharacters,
            },
            {
                // @ts-ignore
                layer: lgaLayer,
                searchFields: ['LGANAME'],
                displayField: 'LGANAME',
                exactMatch: searchConfig.exactMatch,
                outFields: searchConfig.outFields,
                name: 'LGA',
                placeholder: 'type LGA name',
                maxResults: searchConfig.maxResults,
                maxSuggestions: searchConfig.maxSuggestions,
                suggestionsEnabled: searchConfig.suggestionsEnabled,
                minSuggestCharacters: searchConfig.minSuggestCharacters,
            },
            {
                // @ts-ignore
                layer: electoralDistrictsLayer,
                searchFields: ['DISTRICTNAME'],
                displayField: 'DISTRICTNAME',
                exactMatch: searchConfig.exactMatch,
                outFields: searchConfig.outFields,
                name: 'State Electoral District',
                placeholder: 'type electoral district name',
                maxResults: searchConfig.maxResults,
                maxSuggestions: searchConfig.maxSuggestions,
                suggestionsEnabled: searchConfig.suggestionsEnabled,
                minSuggestCharacters: searchConfig.minSuggestCharacters,
            },
        ],
    });

    // UNCOMMENT the code below for any custom sorting.
    //
    // Sort the search suggestion list alphabetically so it can make some sense for users.
    //
    // https://community.esri.com/t5/arcgis-api-for-javascript/search-widget-order-by/td-p/388106
    //
    // The out-of-box way of sorting seems to operate on the SuggestResult::key field, which is
    // the same as SuggestResult::text in getAddressSearchSuggestions(), making this sort redundant.
    // Howerver, the code is left here anyway for future reference.
    //
    // searchWidget.on('suggest-complete', () => {
    //     searchWidget.suggestions.forEach((item) => {
    //         // @ts-ignore
    //         const sortedResults = item.results.map((res) => res);

    //         // @ts-ignore
    //         sortedResults.sort((a, b) => {
    //             return a.text.localeCompare(b.text);
    //         });

    //         // @ts-ignore
    //         // eslint-disable-next-line no-param-reassign
    //         item.results = sortedResults; // We could just sort in-place without using another array.
    //     });
    // });

    // This gets called if a suggestion is selected.
    searchWidget.on('select-result', (event) => {
        console.debug('The selected search result: ', event.result);
    });

    return searchWidget;
}
