import { ActionButton } from 'utils';
import ActionWidget from './ActionWidget';

const CloseWidget = (props: any) => {
  const title = 'Close';

  const button: ActionButton = {
    icon: 'esri-icon-close-circled',
    title: title,
    onClick: () => {

    },
  };

  return (<ActionWidget button={button} />);
}

export default CloseWidget;
