/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core/styles';
import AboutWidget from 'components/About';
import BookmarksWidget from 'components/Bookmarks';
import CloseWidget from 'components/Close';
import HelpWidget from 'components/Help';
import IdentifyWidget from 'components/Identify/Identify';
import ShareWidget from 'components/Share';
import StreetViewWidget from 'components/StreetView';
import SearchBoxWidget from 'components/SearchBox/SearchBox';
import Toolbar from 'components/Toolbar';
import ToolbarGroup from 'components/ToolbarGroup';
import { appTitle } from 'config';
import { ToolbarContextProvider } from 'contexts/toolbar-context';
import { useEffect } from 'react';

export const topRight = document.createElement('div');

const useStyles = makeStyles({
    title: {
        padding: '3px 12px',
        fontSize: 14,
        backgroundColor: '#fff',
        color: '#6e6e6e',
        boxShadow: '0 1px 2px rgb(0 0 0 / 30%)',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
    },
});

const TopRight = (props: any) => {
    const classes = useStyles();
    const { view } = props;
    const position = 'top-right';

    // Adds top right content to view on first render
    useEffect(() => {
        view.ui.add(topRight, position);
    }, []);

    return (
        <div style={{ display: 'flex' }}>
            <ToolbarContextProvider>
                <Toolbar {...props} position={position}>
                    <ToolbarGroup>
                        <IdentifyWidget />
                        <StreetViewWidget />
                        <ShareWidget />
                        <BookmarksWidget />
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <div className={classes.title}><span>{appTitle}</span></div>
                        <AboutWidget />
                        <HelpWidget />
                        <CloseWidget />
                    </ToolbarGroup>
                    <ToolbarGroup>
                        <SearchBoxWidget />
                    </ToolbarGroup>
                </Toolbar>
            </ToolbarContextProvider>
        </div>
    );
};

export default TopRight;