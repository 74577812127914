/* eslint-disable react-hooks/exhaustive-deps */
import AreaWidget from 'components/Area';
import BasemapsWidget from 'components/Basemaps';
import DistanceWidget from 'components/Distance';
import FindWidget from 'components/Find/Find';
import GoToWidget from 'components/GoTo';
import LayerListWidget from 'components/LayerList';
import PrintWidget from 'components/Print/Print';
import Toolbar from 'components/Toolbar';
import ToolbarGroup from 'components/ToolbarGroup';
import { ToolbarContextProvider } from 'contexts/toolbar-context';
import { useEffect } from 'react';

export const topLeft = document.createElement('div');

const TopLeft = (props: any) => {
    const { view } = props;
    const position = 'top-left';

    // Adds top left content to view on first render
    useEffect(() => {
        view.ui.add(topLeft, position);
    }, []);

    return (
        <ToolbarContextProvider>
            <Toolbar {...props} position={position}>
                <ToolbarGroup>
                    <BasemapsWidget />
                    <LayerListWidget />
                </ToolbarGroup>
                <ToolbarGroup>
                    <GoToWidget />
                    <DistanceWidget />
                    <AreaWidget />
                    <PrintWidget />
                    <FindWidget />
                </ToolbarGroup>
            </Toolbar>
        </ToolbarContextProvider>
    );
};

export default TopLeft;