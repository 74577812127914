/* eslint-disable react-hooks/exhaustive-deps */
import * as watchUtils from '@arcgis/core/core/watchUtils';
import { makeStyles } from '@material-ui/core/styles';
import MapContext from 'contexts/mapview-context';
import { useContext, useEffect, useRef } from 'react';

const useStyles = makeStyles({
    webmap: {
        padding: 0,
        margin: 0,
        width: '100%',
        height: '100%',
    }
});

/**
 * Returns the map element
 */
const MapView = (props: any) => {
    const classes = useStyles();
    const mapRef = useRef<HTMLDivElement>(null);
    const { setContainer, view } = useContext(MapContext);

    useEffect(() => {
        if (mapRef.current) {
            // setContainer is used to set the container later used as the view.container property in the initialize function in the map-context.tsx file
            // This essentially allows the view to be added to the html container below where mapRef is used
            setContainer(mapRef.current);

            watchUtils.whenOnce(view, 'container', () => {
                props.setReady(true);
            });
        }
    }, [mapRef.current]);

    return <div className={classes.webmap} ref={mapRef}>
        {props.children}
    </div>;
};

export default MapView;