import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    menulist: {
        backgroundColor: '#fff',
        color: '#6e6e6e',
        boxShadow: '0 1px 2px rgb(0 0 0 / 30%)',
    },
});

/**
 * Provides a consistent dropdown menu
 */
const DropDownComponent = (props: any) => {
    const classes = useStyles();
    const { children } = props;

    if (children === null) {
        return null;
    }

    return (
        <MenuList
            className={classes.menulist}
        >
            {children}
        </MenuList >
    );
};

export default DropDownComponent;