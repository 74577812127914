import Bookmarks from '@arcgis/core/widgets/Bookmarks';
import { useState } from 'react';
import { ToggleButton } from 'utils';
import esriContainer from './EsriContainer';
import ToggleWidget from './ToggleWidget';

const BookmarksWidget = (props: any) => {
  const { view } = props;
  const [active, setActive] = useState(false);
  const title = 'Bookmarks';

  const [bookmarkWidget] = useState(new Bookmarks({
    view,
    container: esriContainer(title, setActive),
    editingEnabled: true,
  }));

  const button: ToggleButton = {
    icon: 'esri-icon-favorites',
    title: title,
  };

  return (<ToggleWidget {...props} widget={bookmarkWidget} button={button} active={active} setActive={setActive} />);
}

export default BookmarksWidget;
