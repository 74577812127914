import { ActionButton } from 'utils';
import ActionWidget from './ActionWidget';

const ZoomInWidget = (props: any) => {
  const { view } = props;
  const title = 'Zoom In';

  const button: ActionButton = {
    icon: 'esri-icon-zoom-in-magnifying-glass',
    title: title,
    onClick: () => {
      view.goTo({ center: view.center, zoom: view.zoom + 1 });
    },
  };

  return (<ActionWidget button={button} />);
}

export default ZoomInWidget;
