import Basemap from '@arcgis/core/Basemap';
import TileLayer from '@arcgis/core/layers/TileLayer';
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';
import MapView from '@arcgis/core/views/MapView';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import LocalBasemapsSource from '@arcgis/core/widgets/BasemapGallery/support/LocalBasemapsSource';
import greyscalePNG from 'assets/images/greyscale.png';
import imageryPNG from 'assets/images/image.png';
import osmJPG from 'assets/images/osm.jpg';
import streetsPNG from 'assets/images/streets.png';
import topoPNG from 'assets/images/topo.png';

export const OSMBasemap = Basemap.fromId('osm');
OSMBasemap.thumbnailUrl = osmJPG;

// The greyscale basemap will be proxied to access secured Geohub maps. The URL should match the proxy configuration.
const greyscaleBasemap = new Basemap({
    baseLayers: [
        new VectorTileLayer({
            url:
                'https://geohub.transport.nsw.gov.au/server/rest/services/Hosted/Transport_Map_Grayscale/VectorTileServer',
            id: 'Grey Scale Basemap',
            title: 'Greyscale',
        }),
    ],
    title: 'Grey Scale',
    id: 'basemap-greyscale',
    thumbnailUrl: greyscalePNG,
});

// The streets basemap will be proxied to access secured Geohub maps. The URL should match the proxy configuration
const streetsBasemap = new Basemap({
    baseLayers: [
        new VectorTileLayer({
            url:
                'https://geohub.transport.nsw.gov.au/server/rest/services/Hosted/Transport_Map_Colour/VectorTileServer',
            id: 'Streets Basemap',
            title: 'Streets',
        }),
    ],
    title: 'Streets',
    id: 'basemap-streets',
    thumbnailUrl: streetsPNG,
});

export const topoBasemap = new Basemap({
    baseLayers: [
        new TileLayer({
            url: 'https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Topo_Map/MapServer',
            title: 'Topo',
        }),
    ],
    title: 'Topo',
    id: 'basemap-topo',
    thumbnailUrl: topoPNG,
});

const imageryBasemap = new Basemap({
    baseLayers: [
        new TileLayer({
            url: 'https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Imagery/MapServer',
            title: 'Image',
        }),
    ],
    title: 'Image',
    id: 'basemap-imagery',
    thumbnailUrl: imageryPNG,
});

// Bringing Open Street Map layer as well. The layer ID string is found here:
// https://developers.arcgis.com/javascript/latest/api-reference/esri-Map.html#basemap
const baseMaps = [OSMBasemap, streetsBasemap, greyscaleBasemap, topoBasemap, imageryBasemap];

// Define custom basemap source.
export const localBasemapsSource = new LocalBasemapsSource({
    basemaps: baseMaps,
});

// Setup the basemap gallery widget with custom basemaps.
// Note that the UBD basemap layer is added technically as a dynamic layer elsewhere in another file.
export function getBasemapGallery(mapview: MapView, container: any): BasemapGallery {
    const basemapGallery = new BasemapGallery({
        view: mapview,
        source: localBasemapsSource,
        container: container,
    });

    return basemapGallery;
}
