import '@arcgis/core/assets/esri/themes/light/main.css';
import esriConfig from '@arcgis/core/config';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import App from './App';
import './index.css';

esriConfig.assetsPath = './assets';

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);
