import { ActionButton } from 'utils';
import ActionWidget from './ActionWidget';

const ZoomOutWidget = (props: any) => {
  const { view } = props;
  const title = 'Zoom Out';

  const button: ActionButton = {
    icon: 'esri-icon-zoom-out-magnifying-glass',
    title: title,
    onClick: () => {
      view.goTo({ center: view.center, zoom: view.zoom - 1 });
    },
  };

  return (<ActionWidget button={button} />);
}

export default ZoomOutWidget;
