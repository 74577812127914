import { jsPDF } from 'jspdf';

// Renders a dataURL to a PDF template and prints the PDF content to a file
export const dataURLToPDFTemplate = (url: string, template: string, title: string) => {
    var doc = new jsPDF({
        orientation: (template === 'A4 Portrait' ? 'p' : 'l'),
        unit: 'px',
        format: 'a4',
    });

    var w = doc.internal.pageSize.getWidth();
    var h = doc.internal.pageSize.getHeight();

    const margin = w * 0.05;

    doc.setFontSize(20);
    doc.text(title, 32, 24);

    doc.addImage(url, 'JPEG', margin, margin, w - margin * 2, h - margin * 2);

    if (title.length === 0) {
        title = 'transportal_template';
    }

    doc.save(`${title}.pdf`);
};
