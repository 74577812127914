import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { Fragment, useState } from 'react';
import ContainerComponent from './Container';
import DraggableWidget from './DraggableWidget';
import ResizableWidget from './ResizableWidget';

const DetailedResultsWidget = (props: any) => {
  const { detailedResults, setSelectedFeatureIndex, onTitleClose } = props;
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(400);
  const title = 'Detailed Results';
  
  const keys = Object.keys(detailedResults.length > 0 ? detailedResults[0] : []);

  const exportResults = () => {
    const rows: any = [];
    const fields: any = [];

    keys.forEach((key: any) => {
      fields.push(key);
    });

    rows.push(fields);

    detailedResults.forEach((result: any) => {
      const values: any = [];
      fields.forEach((field: any) => {
        values.push(result[field]);
      });
      rows.push(values);
    });

    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e: any) => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'detailed_results.csv');
    link.click();
  };

  return (
    <DraggableWidget handle='.draggable'>
      <ContainerComponent title={title} onTitleClose={onTitleClose} setActive={detailedResults}>
        <ResizableWidget
          width={width}
          height={height}
          setWidth={setWidth}
          setHeight={setHeight}
          minConstraints={[400, 400]}
          maxConstraints={[800, 800]}
        >
          <Fragment>
            <TableContainer style={{ width: '100%', maxHeight: 300, overflow: 'auto' }}>
              <Table size='small' style={{ width: '100%' }}>
                <TableBody>
                  <TableRow>
                    {keys.map((key: string) => <TableCell>{key}</TableCell>)}
                  </TableRow>
                  {detailedResults.map((result: any, index: number) => {
                    return (
                      <TableRow
                        onDoubleClick={() => setSelectedFeatureIndex(index)}
                      >
                        {keys.map((key: string) => <TableCell>{result[key]}</TableCell>)}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {detailedResults.length > 0 && <Button onClick={() => exportResults()}>Export Results</Button>}
          </Fragment>
        </ResizableWidget>
      </ContainerComponent>
    </DraggableWidget>
  );
};

export default DetailedResultsWidget;
