import { ActionButton } from 'utils';
import ActionWidget from './ActionWidget';

const HelpWidget = (props: any) => {
  const title = 'Help';

  const button: ActionButton = {
    icon: 'esri-icon-question',
    title: title,
    onClick: () => {

    },
  };

  return (<ActionWidget button={button} />);
}

export default HelpWidget;
