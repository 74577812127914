/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState } from 'react';

interface ContextProps {
    tools: any;
    setTools: any;
    registerTool: any;
    openTools: any;
    closeTools: any;
};

const ToolbarContext = createContext<ContextProps>({
    tools: undefined,
    setTools: () => { },
    registerTool: (_e: any) => { },
    openTools: () => { },
    closeTools: () => { },
});

export const ToolbarContextProvider = (props: any) => {
    const { children } = props;
    const [tools, setTools] = useState<any>({});

    const registerTool = (id: string) => {
        tools[id] = {
            active: false,
        };

        setTools(tools);
    };

    const openTools = (t: Array<any>) => {
        t.forEach((tool: string) => {
            if (tools[tool]) {
                tools[tool].active = true;
            }
        });

        setTools({ ...tools });
    };

    const closeTools = (t: Array<any>) => {
        t.forEach((tool: string) => {
            if (tools[tool]) {
                tools[tool].active = false;
            }
        });

        setTools({ ...tools });
    };

    const value = {
        tools,
        setTools,
        registerTool,
        openTools,
        closeTools,
    };

    return <ToolbarContext.Provider value={value}>
        {children}
    </ToolbarContext.Provider>;
};

export default ToolbarContext;
