import indigo from '@material-ui/core/colors/indigo';

export type ContainerTitle = string | null | undefined;

const esriContainer = (title: ContainerTitle, setActive: any) => {
    const onClose = () => {
        setActive(false);
    };

    const container = document.createElement('div');

    // If a title is supplied (as string) then we will add the titlebar to it.
    if (!!title) {
        const titlebar = document.createElement('div');
        titlebar.style.width = '100%';
        titlebar.style.height = '1.75rem';
        titlebar.style.display = 'flex';
        titlebar.style.flexDirection = 'row';
        titlebar.style.justifyContent = 'space-between';
        titlebar.style.alignItems = 'center';
        titlebar.style.textTransform = 'uppercase';
        titlebar.style.letterSpacing = '0.2rem';
        titlebar.style.borderBottom = '1px solid #ccc';
        titlebar.style.backgroundColor = indigo[200];
        titlebar.style.color = '#eee';
        titlebar.style.padding = '1.1rem 0'; // Make the height consistent with the non-Esri containers.

        titlebar.innerHTML += `<span></span>${title}`;

        const closeHTML = document.createElement('span') as HTMLSpanElement;
        closeHTML.onclick = onClose;
        closeHTML.className = 'esri-icon-close';
        closeHTML.style.cursor = 'pointer';
        closeHTML.style.paddingRight = '0.5rem';
        titlebar.appendChild(closeHTML);
        container.appendChild(titlebar);
    }

    return container;
};

export default esriContainer;