
import { ReactElement } from 'react';
import { Resizable } from 'react-resizable';

type Props = {
    width: number;
    height: number;
    setWidth: Function;
    setHeight: Function;
    minConstraints: [number, number];
    maxConstraints: [number, number];
    onResize?: Function,
    onResizeStart?: Function,
    onResizeStop?: Function,
    children: ReactElement;
};

/**
 * Provides a resizable container with default dimensions and contraints
 */
const ResizableWidget = (props: Props) => {
    const { children, width, height, setWidth, setHeight, minConstraints, maxConstraints, onResize, onResizeStart, onResizeStop } = props;

    const onResizing = (event: any, data: any) => {
        const { size } = data;
        setWidth(size.width);
        setHeight(size.height);
        
        if (onResize)
            onResize(event, data);
    };

    const onResizingStart = (event: any, data: any) => {
        if (onResizeStart)
            onResizeStart(event, data);
    };

    const onResizingStop = (event: any, data: any) => {
        if (onResizeStop)
            onResizeStop(event, data);
    };

    return (
        <Resizable
            width={width}
            height={height}
            minConstraints={minConstraints}
            maxConstraints={maxConstraints}
            onResize={onResizing}
            onResizeStart={onResizingStart}
            onResizeStop={onResizingStop}
        >
            <div>
                {/* <div style={{ width: width, height: height, paddingBottom: 18 }}> */}
                <div style={{ width: width, height: height }}>
                    {children}
                </div>
            </div>
        </Resizable >
    );
};

export default ResizableWidget;