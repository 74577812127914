/* eslint-disable react-hooks/exhaustive-deps */
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import WMSLayer from '@arcgis/core/layers/WMSLayer';
import LayerList from '@arcgis/core/widgets/LayerList';
import { layers } from 'config';
import { useEffect, useState } from 'react';
import { ToggleButton } from 'utils';
import esriContainer from './EsriContainer';
import ToggleWidget from './ToggleWidget';

const LayerListWidget = (props: any) => {
  const { view } = props;
  const [active, setActive] = useState(false);
  const title = 'Layer List';

  const [layerListWidget] = useState(new LayerList({
    view,
    container: esriContainer(title, setActive),
    listItemCreatedFunction: (event) => {
      const { item } = event;

      if (item.layer.layerType) {
        item.panel = {
          content: 'legend',
          open: false,
        };
      }

      if (item.children.items.length > 0) {
        item.actionsSections = [
          [
            {
              title: 'Show Layers',
              className: 'esri-icon-visible',
              id: 'show-layers',
            },
            {
              title: 'Hide Layers',
              className: 'esri-icon-non-visible',
              id: 'hide-layers',
            }
          ],
        ];

        if (item.children.items.reduce((a: any, b: any) => a + b.children.items.length, 0) > 0) {
          item.actionsSections.push([
            {
              title: 'Expand All',
              className: 'esri-icon-expand',
              id: 'expand-items',
            },
            {
              title: 'Collapse All',
              className: 'esri-icon-collapse',
              id: 'collapse-items',
            }
          ]);
        }
      }
    },
  }));

  const showLayers = (layer: any) => {
    layer.visible = true;

    layer?.sublayers?.items?.forEach((sublayer: any) => {
      showLayers(sublayer);
    });
  };

  const hideLayers = (layer: any) => {
    layer.visible = false;

    layer?.sublayers?.items?.forEach((sublayer: any) => {
      hideLayers(sublayer);
    });
  };

  const expandItems = (item: any) => {
    item.open = true;

    item?.children?.items?.forEach((child: any) => {
      expandItems(child);
    });
  };

  const hideItems = (item: any) => {
    item.open = false;

    item?.children?.items?.forEach((child: any) => {
      hideItems(child);
    });
  };

  useEffect(() => {
    layerListWidget.on('trigger-action', (event: any) => {
      const { item, action } = event;
      let layer = item.layer;

      if (layer.layer) {
        layer = layer.layer;
      }

      if (action.id === 'show-layers') {
        showLayers(layer);
      }
      if (action.id === 'hide-layers') {
        hideLayers(layer);
      }
      if (action.id === 'expand-items') {
        expandItems(item);
      }
      if (action.id === 'collapse-items') {
        hideItems(item);
      }
    });
  }, []);

  // Recursive function to add legend urls to WMS layers and sublayers
  const addWMSLegendUrl = (url: string, config: any) => {
    if (config.name) {
      config.legendUrl = `${url}?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=${config.name}`;
    }

    config?.sublayers?.forEach((sublayer: any) => {
      addWMSLegendUrl(url, sublayer);
    });
  };

  // Recursive function to add identify urls to WMS layers and sublayers
  const addWMSIdentifyUrl = (config: any) => {
    config?.sublayers?.forEach((sublayer: any) => {
      sublayer.identifyURL = config.identifyURL;
      addWMSIdentifyUrl(sublayer);
    });
  };

  const createLayer = (config: any, url: string, type: string) => {
    if (type === 'mapserver') {
      return new MapImageLayer(config);
    }

    if (type === 'wms') {
      addWMSLegendUrl(url, config);
      addWMSIdentifyUrl(config);
      return new WMSLayer(config);
    }
  };

  useEffect(() => {
    layers.reverse().forEach((layer: any) => {
      const { url, layerType: type } = layer;

      if (layer.layers) {
        const groupLayers = layer.layers.reverse().map((l: any) => createLayer(l, url, type));

        if (groupLayers.length === 0) {
          return;
        }

        const groupLayer = new GroupLayer({
          title: layer.title,
          visible: layer.visible,
          visibilityMode: layer.visibilityMode,
          layers: groupLayers,
        });

        view.map.add(groupLayer);
      } else {
        if (layer.sublayers) {
          layer.sublayers = layer.sublayers.reverse();
        }
        view.map.add(createLayer(layer, url, type));
      }
    });
  }, []);

  const button: ToggleButton = {
    icon: 'esri-icon-layers',
    title: title,
  };

  return (<ToggleWidget {...props} widget={layerListWidget} button={button} active={active} setActive={setActive} />);
}

export default LayerListWidget;
