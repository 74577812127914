/* eslint-disable react-hooks/exhaustive-deps */
import ScaleBar from '@arcgis/core/widgets/ScaleBar';
import { useEffect } from 'react';

export const bottomLeft = document.createElement('div');

const BottomLeft = (props: any) => {
    const { view } = props;

    // Adds scalebar widget to view on first render
    useEffect(() => {
        const scaleBarWidget = new ScaleBar({
            view: view,
            unit: 'metric',
            style: 'line',
        });

        view.ui.add(scaleBarWidget, 'bottom-left');
    }, []);

    return null;
};

export default BottomLeft;