/* eslint-disable react-hooks/exhaustive-deps */

import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { render } from 'react-dom';
import { addWidget, removeWidget } from 'utils';

const useStyles = makeStyles({
  toolbarButton: {
    boxShadow: '0 1px 2px rgb(0 0 0 / 30%)',
  },
  mapToolbarActive: {
    backgroundColor: '#e2f1fb',
    borderBottom: '3px solid #3F51B5', // Based on primary colour.
  },
});

/**
 * Takes a widget/html prop and provides a toggleable widget (show and hide with button click)
 */
const ToggleWidget = (props: any) => {
  const classes = useStyles();
  let { view, widget, html, button, position, active, setActive } = props;
  const [target] = useState<HTMLElement>(document.createElement('div'));

  const { icon, title, onActivate, onDeactivate } = button;

  // If widget isn't provided then html is rendered to target and set as widget
  if (!widget) {
    render(html, target);
    widget = target;

    // Pointer events set so that widget container ignores pointer events so that the map can be clicked when widget is dragged from initial position
    widget.style.pointerEvents = 'none';

    if (widget.firstChild) {
      widget.firstChild.style.pointerEvents = 'auto';
    }
  }

  // When active changes this block will call the widget onActivate/onDeactivate function and add or remove the widget
  useEffect(() => {
    if (active) {
      if (onActivate) {
        onActivate();
      }
      addWidget(view, widget, position);
      return;
    }

    if (onDeactivate) {
      onDeactivate();
    }
    removeWidget(view, widget);
  }, [active]);

  const onClick = () => {
    setActive(!active);
  };

  // Returns a button for the widget, styling the button depending on active state
  return (
    <div>
      <div
        role='button'
        key={title}
        title={title}
        tabIndex={0}
        className={`${active ? classes.mapToolbarActive : ''} ${classes.toolbarButton} esri-home esri-widget--button esri-widget`}
        onClick={onClick}
        onKeyPress={(e) => {
          if (e.key === 'Enter') onClick();
        }}
        aria-label={title}
      >
        {/* <span className={`esri-icon ${icon}`} aria-hidden='true' /> */}
        <span className={`${icon}`} aria-hidden='true' />
        <span className='esri-icon-font-fallback-text'>{title}</span>
      </div>
    </div>
  );
};

export default ToggleWidget;