import Graphic from '@arcgis/core/Graphic';
import Geometry from '@arcgis/core/geometry/Geometry';
import MapView from '@arcgis/core/views/MapView';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import { createGeometry, NSWLambert, projectToWebMercator } from 'utils';

// Symbol styling
const width = 2;
const size = 8;
const fill = [255, 0, 0, 0.25];

const line = [255, 0, 0, 1];
const highlightedLine = [255, 255, 0, 1];

let highlightedGraphic: Graphic;

// Adds a pointer move event handler for detecting graphics and rendering tooltips / highlighting graphics
export const initialiseGraphics = (view: MapView) => {
    const eventHandler = (event: any) => {
        view.hitTest(event).then((response: any) => getGraphics(response, view));
    }

    view.on('pointer-move', eventHandler);
};

// Renders tooltip for graphic and highlights graphic
export const getGraphics = (response: any, view: MapView) => {
    const tooltip = document.getElementById('tooltip');

    view.graphics.remove(highlightedGraphic);

    if (response.results.length) {
        const graphic = response.results[0].graphic;

        if (!graphic?.attributes?.tooltipText) {
            return;
        }

        highlightedGraphic = createGraphic(graphic.attributes.geometry, graphic.attributes.tooltipText, true) as Graphic;

        view.graphics.add(highlightedGraphic);

        if (tooltip && graphic?.attributes?.tooltipText) {
            tooltip.style.top = response.screenPoint.y - 20 + 'px';
            tooltip.style.left = response.screenPoint.x + 'px';
            tooltip.innerHTML = graphic.attributes.tooltipText;
            tooltip.style.visibility = 'visible';
        }
    } else {
        if (tooltip) {
            tooltip.style.visibility = 'hidden';
        }
    }
};

// Adds graphic to view. Removing all current graphics and zooming to graphic is optional
export const addGraphic = (view: MapView, graphic: Graphic | null, zoomTo?: boolean, removeAll?: boolean) => {
    if (!graphic) {
        return;
    }

    if (removeAll) {
        view.graphics.removeAll();
    }

    view.graphics.add(graphic);

    if (zoomTo) {
        if (graphic.attributes.geometry.type === 'point') {

            return;
        }
        view.goTo(graphic);
    }
};

// Creates graphic based on geometry type
export const createGraphic = (geometry: any, tooltipText: string, highlighted?: boolean): Graphic | null => {
    let symbol;

    switch (geometry.type) {
        case 'point':
            symbol = createMarker(highlighted);
            break;
        case 'polygon':
            symbol = createFill(highlighted);
            break;
        case 'polyline':
            symbol = createLine(highlighted);
            break;
        default:
            console.log('Unsupported type:', geometry.type);
            return null;
    };

    const graphic = new Graphic({
        geometry: geometry,
        symbol: symbol,
        attributes: {
            geometry: geometry,
            tooltipText: tooltipText,
        },
    });

    return graphic;
};

export const createWebMercatorGraphic = (inputGeometryJson: Geometry, tooltipText: string, highlighted?: boolean) => {
    let symbol;

    switch (inputGeometryJson.type) {
        case 'point':
            symbol = createMarker(highlighted);
            break;
        case 'polygon':
            symbol = createFill(highlighted);
            break;
        case 'polyline':
            symbol = createLine(highlighted);
            break;
        default:
            console.log('Unsupported type:', inputGeometryJson.type);
            return null;
    };

    const webMercatorGeometry: Geometry = projectToWebMercator(inputGeometryJson);

    const graphic = new Graphic({
        geometry: webMercatorGeometry,
        symbol: symbol,
        attributes: {
            geometry: webMercatorGeometry,
            tooltipText: tooltipText,
        },
    });

    return graphic;
};

const createMarker = (highlighted?: boolean) => {
    return {
        type: 'simple-marker',
        color: highlighted ? [0, 0, 0, 0] : fill,
        size: size,
        outline: {
            width: highlighted ? width + 1 : width,
            color: highlighted ? highlightedLine : line,
        },
    };
};

const createFill = (highlighted?: boolean) => {
    return {
        type: 'simple-fill',
        color: highlighted ? [0, 0, 0, 0] : fill,
        outline: {
            width: highlighted ? width + 1 : width,
            color: highlighted ? highlightedLine : line,
        },
    };
};

const createLine = (highlighted?: boolean) => {
    return {
        type: 'simple-line',
        color: highlighted ? highlightedLine : line,
        width: width,
    };
};

// Shortcut to add a graphic based on fetch response
type onGraphicAddedCallback = (graphic: Graphic | null) => any;
export const addNSWLambertGraphicFromResponse = async (view: MapView, response: any, tooltipText: string, onGraphicAddedCallback?: onGraphicAddedCallback) => {
    const data = await response.json();
    if (!data || !data.features || data.features?.length === 0)
        return;

    // Merge multiple geometries e.g. postcode 2150.
    const geometries = data.features.map((f: any) => {
        const g = createGeometry(f.geometry, data.geometryType, NSWLambert.wkid);
        return g;

    });
    const totalGeometry = geometryEngine.union(geometries);

    const graphic = createWebMercatorGraphic(totalGeometry, tooltipText);

    addGraphic(view, graphic, true);

    // A callback on after a graphic is added.
    if (onGraphicAddedCallback)
        onGraphicAddedCallback(graphic);
};