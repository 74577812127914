/* eslint-disable react-hooks/exhaustive-deps */
import Extent from '@arcgis/core/geometry/Extent';
import Geometry from '@arcgis/core/geometry/Geometry';
import Point from '@arcgis/core/geometry/Point';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addGraphic, createGraphic } from 'graphics';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { createGeometry, request, ToggleButton, NSWLambert, projectToNSWLambert, projectToWebMercator } from 'utils';
import ContainerComponent from '../Container';
import DraggableWidget from '../DraggableWidget';
import ResizableWidget from '../ResizableWidget';
import ToggleWidget from '../ToggleWidget';
import Graphic from '@arcgis/core/Graphic';
import './Identify.css';

let mapPoint: Point | null = null; // The map point where user clicked to identify.
let pointerUpHandle: any = undefined;
let dragEventHandle: any = undefined;

const DEFAULT_NO_RESULTS_MESSAGE = "Click on map to start identifying features.";
const NO_FEATURES_IDENTIFIED_MESSAGE = "No features were identified at the map location.";
const IDENTIFY_ERRROR_MESSAGE = "An error has occurred, unable to identify features.";
const NO_LAYERS_TO_IDENTIFY = "Please ensure layers are visible so features can be identified."
const DEFAULT_IDENTIFIED_FEATURES_LABEL = "IDENTIFIED FEATURES";

/**
 * This tool currently works with TransPortal NSW Lambert ArcGIS map services.
 * It will need to be changed over to the WGS-84 GeoServer WMS services sometime.
 * 
 * TODO: Handle too many points.
 */
const IdentifyWidget = (props: any) => {
  const { view } = props;
  const [selected, setSelected] = React.useState<any>(undefined);
  const [results, setResults] = useState<any>({});
  const [layers, setLayers] = useState<any>({});
  const [isIdentifying, setIsIdentifying] = useState(false);
  const [g, setGraphic] = useState<any>(undefined);
  const [active, setActive] = useState(false);
  const [firstTimeWithResult, setFirstTimeWithResult] = useState(false); // Make the dialog initially small to avoid take up too much screen space.
  const [featureCountLabel, setFeatureCountLabel] = useState(DEFAULT_IDENTIFIED_FEATURES_LABEL);
  const [width, setWidth] = useState(288);
  const [height, setHeight] = useState(144);
  const [noResultsMessage, setNoResultsMessage] = useState(DEFAULT_NO_RESULTS_MESSAGE);
  const isWidgetResizing = useRef(false);

  const title = 'Identify Results';

  useEffect(() => {
    if (firstTimeWithResult) {
      setWidth(800);
      setHeight(400);
    }
  }, [firstTimeWithResult]);

  useEffect(() => {
    if (results) {
      setLayers({ ...layers, ...results });
    }
  }, [results]);

  const button: ToggleButton = {
    icon: 'esri-icon-description',
    title: title,
    onActivate: () => {
      view.surface.style.cursor = 'pointer';
      pointerUpHandle = view.on('pointer-up', handlePointerUp);
      dragEventHandle = view.on('drag', handleDrag);
    },
    onDeactivate: () => {
      view.surface.style.cursor = 'default';

      if (pointerUpHandle) {
        pointerUpHandle.remove();
        pointerUpHandle = null;
      }

      if (dragEventHandle) {
        dragEventHandle.remove();
        dragEventHandle = null;
      }

      view.graphics.removeAll();
    },
  };

  const table = selected && (
    <TableContainer style={{ width: '100%' }}>
      <Table size='small' style={{ width: '100%' }}>
        <TableBody>
          {Object.keys(selected.attributes).filter((key: string) => !['OBJECTID', 'Shape'].includes(key)).map((key: string) => {
            const attribute = selected.attributes[key];

            return (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell>{attribute}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const getVisibleLayers = (layers: any, visibleLayers: any) => {
    layers.items.forEach((l: any) => {
      if (l.sublayers) {
        getVisibleLayers(l.sublayers, visibleLayers);
      } else if (l.visible) {
        visibleLayers.push(l);
      }
    });

    return visibleLayers;
  };

  const onIdentifySuccess = async (response: any) => {
    const data = await response.json();

    if (!data || !data.results) {
      handleIdentifyError(null);
      return;
    }

    const l: any = {};

    const graphic = createGraphic(mapPoint, '');

    addGraphic(view, graphic, false, false);

    for (let record of data.results) {
      const { layerName } = record;

      if (!Object.keys(l).includes(layerName)) {
        l[layerName] = [];
      }
      l[layerName].push(record);
    }

    setFeatureCountLabel( (`IDENTIFIED ${data.results.length} `) + (data.results.length <= 1 ? "FEATURE" : "FEATURES") );

    if (!firstTimeWithResult)
      setFirstTimeWithResult(true);

    // Setting the result will also trigger the UI to be updated with useEffect.
    setResults(l);

    setNoResultsMessage(Object.keys(l).length === 0 ? NO_FEATURES_IDENTIFIED_MESSAGE : "");

    setIsIdentifying(false);
  };

  const onIdentifyError = (err: any) => {
    handleIdentifyError(err);
  };

  const identifyClick = (nswLambertPoint: Point, extent: Geometry) => {
    const visibleLayers = getVisibleLayers(view.map.layers, []);

    const visibleLayerGroups: any = {};

    visibleLayers.forEach((visibleLayer: any) => {
      if (!(visibleLayer.identifyURL in visibleLayerGroups)) {
        visibleLayerGroups[visibleLayer.identifyURL] = [];
      }

      visibleLayerGroups[visibleLayer.identifyURL].push(visibleLayer);
    });

    // Are there visible layers to be identified?
    if (Object.keys(visibleLayerGroups).length === 0) {
      resetAll(NO_LAYERS_TO_IDENTIFY);
      return;
    }

    view.graphics.removeAll();
    setLayers({});

    const { xmin, ymin, xmax, ymax } = (extent as Extent);
    const extentString = [xmin, ymin, xmax, ymax].join(',');

    Object.keys(visibleLayerGroups).forEach((key: any) => {
      const baseURL = key;

      const visibleLayerIds = visibleLayerGroups[key].map((layer: any) => layer.identifyID).join(',');

      const params: any = {
        f: 'json',
        tolerance: 10,
        returnGeometry: true,
        returnFieldName: false,
        returnUnformattedValues: false,
        imageDisplay: `${view.width},${view.height},96`,
        geometry: `{x: ${nswLambertPoint.x}, y: ${nswLambertPoint.y}}`,
        geometryType: 'esriGeometryPoint',
        sr: NSWLambert.wkid,
        mapExtent: extentString,
        layers: `all:${visibleLayerIds}`,
      };

      // Using URL request rather than the ArcGIS JS API Identify task for easier switching to GeoServer which is based on GET request.
      request(baseURL, params, onIdentifySuccess, onIdentifyError, undefined);
    });
  };

  useEffect(() => {
    if (!selected) {
      return;
    }

    const geometry = createGeometry(selected.geometry, selected.geometryType);
    const mapGeometry = projectToWebMercator(geometry as any);

    const graphic = createGraphic(mapGeometry, renderToStaticMarkup(table));

    addGraphic(view, graphic, false, true);

    setGraphic(graphic);

  }, [selected]);

  const zoomToSelectedFeature = (selectedMapGraphic: Graphic) => {
    const type = selectedMapGraphic.geometry.type;

    if (type === 'point') {
      const geometry = selectedMapGraphic.geometry;
      view.goTo({ center: geometry, zoom: 15 });
    } else {
      view.goTo(selectedMapGraphic.geometry).then((e: any) => {
        if (view.zoom !== 0)
          view.zoom--;
      });
    }
  };

  const handleIdentifyError = (err: any) => {
    console.error(err ?? "Failed to identify.");
    resetAll(IDENTIFY_ERRROR_MESSAGE);
  };

  const resetAll = (message: string) => {
    view.graphics.removeAll();
    setIsIdentifying(false);
    setSelected(undefined);
    setFeatureCountLabel(DEFAULT_IDENTIFIED_FEATURES_LABEL);
    setLayers({});
    setResults({});
    setNoResultsMessage(message);
  };

  const resizingStart = (event: any, data: any) => {
    isWidgetResizing.current = true;
  };

  const resizingStop = (event: any, data: any) => {
    isWidgetResizing.current = false;
  };

  // TODO: Is there a way to abstract the html out to another file, as HTML could potentially
  // gets large (I suppose in which case it needs to be refactored into different components/files)?
  // Apparently there is this function "dangerouslySetInnerHTML()" but not a good practice to use it;
  // also there are 3rd party libs out there we could use.
  // renderToStaticMarkup from 'react-dom/server' can be used to convert React elements to static html, I am sure there are options for what you are after
  const html = (
    <DraggableWidget handle={'.draggable'}>
      <ContainerComponent title={title} onTitleClose={() => setActive(false)}>
        <ResizableWidget
          width={width}
          height={height}
          setWidth={setWidth}
          setHeight={setHeight}
          minConstraints={[288, 144]}
          maxConstraints={[1200, 1200]}
          onResizeStart={resizingStart}
          onResizeStop={resizingStop}
        >
          <Fragment>
            { (!isIdentifying && Object.keys(results).length === 0) &&
              <div className="no-result">
                <h3>{noResultsMessage}</h3>
              </div>
            }

            { (Object.keys(results).length > 0 && !isIdentifying) &&
              <div className="results-container">

                {/* Horizontal container for the layer tree and attribute table. */}
                <div className="results-data-container">

                  {/* Layer tree on left side. */}
                  <div className="results-layers-tree-container">
                    <h4>{featureCountLabel}</h4>
                    <div className="results-layers-tree">
                      {Object.keys(layers).map((key: any) => {
                        const layer = layers[key];

                        layer.sort((a: any, b: any) => {
                          const labelA = a.attributes[a.displayFieldName];
                          const labelB = b.attributes[b.displayFieldName];

                          if (labelA < labelB) {
                            return -1;
                          }
                          if (labelA > labelB) {
                            return 1;
                          }
                          return 0;
                        });

                        return (
                          <TreeView
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                          >
                            <TreeItem
                              nodeId={key}
                              label={key}
                            >
                              {layer.map((record: any) => {
                                const { attributes, displayFieldName } = record;
                                const { OBJECTID } = attributes;

                                return (
                                  <TreeItem
                                    nodeId={OBJECTID}
                                    label={`${attributes[displayFieldName]}`}
                                    onClick={() => setSelected(record)}
                                  />
                                );
                              })}
                            </TreeItem>
                          </TreeView>
                        );
                      })}
                    </div>
                  </div>
                  
                  {/* Attributes list on right side. */}
                  <div className="results-selected-feature-attributes-containers">
                    <h4>SELECTED FEATURE ATTRIBUTES</h4>
                    {table}
                  </div>
                </div>

                {/* Buttons row. */}
                <div className="results-buttons-container">
                  <Button className="results-button" variant="outlined" onClick={() => zoomToSelectedFeature(g)}>Zoom To</Button>
                  <Button className="results-button" onClick={() => resetAll(DEFAULT_NO_RESULTS_MESSAGE)}>Clear</Button>
                </div>
              </div> 
            }

            {isIdentifying && 
              <div className="in-progress">
                <CircularProgress />
                <h3>Identifying map features, please wait&#8230;</h3>
              </div>
            }

          </Fragment>
        </ResizableWidget>
      </ContainerComponent>
    </DraggableWidget >
  );

  const handlePointerUp = (event: any) => {
    // Prevents a bug where identify is triggered by the mouse-up event at the end of resizing the dialog.
    if (isWidgetResizing.current)
      return null;

    const { x, y } = event;

    const screenPoint = { x, y } as __esri.MapViewScreenPoint;

    mapPoint = view.toMap(screenPoint);

    const nswLambertPoint: Point = projectToNSWLambert(mapPoint as Point) as Point;

    if (!nswLambertPoint) {
      return null;
    }

    // This will show the progress indicator.
    setIsIdentifying(true);

    const nswLambertExtent: Geometry = projectToNSWLambert(view.extent);

    identifyClick(nswLambertPoint, nswLambertExtent);
  };

  const handleDrag = (event: any) => {
    if (event.action === 'start') {
      if (pointerUpHandle) {
        pointerUpHandle.remove();
        pointerUpHandle = null;
      }
    }

    if (event.action === 'end') {
      if (!pointerUpHandle) {
        pointerUpHandle = view.on('pointer-up', handlePointerUp);
      }
    }
  };

  return (<ToggleWidget {...props} html={html} button={button} active={active} setActive={setActive} />);
};

export default IdentifyWidget;
