/* eslint-disable react-hooks/exhaustive-deps */

import Point from '@arcgis/core/geometry/Point';
import * as projection from '@arcgis/core/geometry/projection';
import { makeStyles } from '@material-ui/core/styles';
import MapContext from 'contexts/mapview-context';
import { useContext, useEffect, useState } from 'react';
import { GDA1994, MGAZone54, MGAZone55, MGAZone56, NSWLambert, WGS1984, WebMercator } from 'utils';

const useStyles = makeStyles({
    coordinates: {
        height: 20,
        padding: '3px 6px',
        fontSize: 14,
        backgroundColor: '#fff',
        color: '#6e6e6e',
        boxShadow: '0 1px 2px rgb(0 0 0 / 30%)',
        cursor: 'pointer',
    }
});

const CoordinatesTracker = (props: any) => {
    const classes = useStyles();
    const [screenPoint, setScreenPoint] = useState<__esri.MapViewScreenPoint>({ x: 0, y: 0 });
    const [srIndex, setSRIndex] = useState<number>(0);

    const spatialRefs = [
        { label: 'WGS 1984', sr: WGS1984, dp: 4 },
        { label: 'NSW Lambert', sr: NSWLambert, dp: 2 },
        { label: 'GDA 1994', sr: GDA1994, dp: 4 },
        { label: 'MGA Zone 54', sr: MGAZone54, dp: 4 },
        { label: 'MGA Zone 55', sr: MGAZone55, dp: 4 },
        { label: 'MGA Zone 56', sr: MGAZone56, dp: 4 },
        { label: 'Web Mercator', sr: WebMercator, dp: 4 },
    ];

    const context = useContext(MapContext);

    useEffect(() => {
        context.view.on('pointer-move', (event: any) => {
            if (!event) {
                return;
            }

            const { x, y } = event;

            if (x && y) {
                setScreenPoint({ x, y });
            }
        });

        projection.load();
    }, []);

    const onClick = () => {
        setSRIndex((srIndex + 1) % spatialRefs.length);
    };

    if (!context.view) {
        return null;
    }

    const point = context.view.toMap(screenPoint);

    if (!point || !projection.isLoaded()) {
        return null;
    }

    const convertedPoint = projection.project(point, spatialRefs[srIndex].sr) as Point;

    return (
        <div
            className={classes.coordinates}
            onClick={onClick}
        >
            {spatialRefs[srIndex].label}, X: {convertedPoint.x.toFixed(spatialRefs[srIndex].dp)}, Y: {convertedPoint.y.toFixed(spatialRefs[srIndex].dp)}
        </div>
    );
}

export default CoordinatesTracker;
