import MenuItem from '@material-ui/core/MenuItem';
import PrintScreenshot from 'components/Print/PrintScreenshot';
import PrintTemplate from 'components/Print/PrintTemplate';
import SelectComponent from 'components/Select';
import ToggleWidget from 'components/ToggleWidget';
import { useState } from 'react';
import { ToggleButton } from 'utils';
import DraggableWidget from '../DraggableWidget';
import ContainerComponent from '../Container';

const PrintWidget = (props: any) => {
    const { view, map } = props;
    const [print, setPrint] = useState('Template');
    const [active, setActive] = useState(false);
    const title = 'Print';

    const html = (
        <DraggableWidget handle={'.draggable'}>
            <ContainerComponent title={title} onTitleClose={() => setActive(false)}>
                <SelectComponent
                    value={print}
                    setValue={setPrint}
                    label='Print'
                >
                    <MenuItem value={'Template'}>Template</MenuItem>
                    <MenuItem value={'Screenshot'}>Screenshot</MenuItem>
                </SelectComponent>
                {print === 'Template' && <PrintTemplate view={view} />}
                {print === 'Screenshot' && <PrintScreenshot view={view} map={map} />}
            </ContainerComponent>
        </DraggableWidget>
    );

    const button: ToggleButton = {
        icon: 'esri-icon-printer',
        title: title,
    };

    return (<ToggleWidget {...props} html={html} button={button} active={active} setActive={setActive} />);
};

export default PrintWidget;
