/* eslint-disable react-hooks/exhaustive-deps */
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import { IconButton, MenuItem } from '@material-ui/core';
import SelectComponent from 'components/Select';
import { query } from 'config';
import { Fragment, useEffect, useState } from 'react';
import { request } from 'utils';
import { findRequestCallback, NSWLambert } from 'utils';

const Spatial = (props: any) => {
  const { view, map, layer, setLayer } = props;
  const queryConfig = query.find;

  const [graphicsLayer] = useState(new GraphicsLayer());
  const [graphic, setGraphic] = useState<Graphic | undefined>();
  const [geometry, setGeometry] = useState<any | undefined>();
  const [config, setConfig] = useState<any | undefined>('ferryRoutes');
  const [drawType, setDrawType] = useState<'polyline' | 'circle' | 'polygon' | 'point' | 'multipoint' | 'rectangle' | undefined>(undefined);

  useEffect(() => {
    setLayer('Ferry Routes');
    map.add(graphicsLayer);
  }, []);

  useEffect(() => {
    if (geometry) {
      updateGraphics();
    }
  }, [geometry]);

  useEffect(() => {
    switch (layer) {
      case 'All Metro Bus Routes':
        setConfig('metroBusRoutes');
        break;
      case 'Bus Stops':
        setConfig('busStops');
        break;
      case 'Ferry Routes':
        setConfig('ferryRoutes');
        break;
      // case 'Ferry Wharf':
      //   setConfig('ferryWharf');
      //   break;
      case 'Metro Bus Interchange':
        setConfig('metroBusInterchange');
        break;
      case 'OPAL Retailers':
        setConfig('opalRetailers');
        break;
      case 'Rail Stations':
        setConfig('railStations');
        break;
      case 'Regional Coaches':
        setConfig('regionalCoaches');
        break;
      case 'Rural Contract A Routes':
        setConfig('ruralContractARoutes');
        break;
      case 'Rural Line of Route':
        setConfig('ruralLineOfRoute');
        break;
      case 'Rural Service Region':
        setConfig('ruralServiceRegion');
        break;
      default:
        console.log('Unsupported layer:', layer);
    };

  }, [layer]);

  useEffect(() => {
    if (drawType) {
      view.surface.style.cursor = 'pointer';

      const sketch = new SketchViewModel({
        view: view,
        layer: graphicsLayer,
      });

      sketch.on('create', async (event: any) => {
        if (event.state === 'complete') {
          setGraphic(event.graphic);
          sketch.destroy();
        }
      });

      sketch.create(drawType);

      return () => {
        sketch.destroy();
      };

    } else {
      view.surface.style.cursor = 'default';
    }
  }, [drawType]);

  useEffect(() => {
    if (graphic) {
      const { geometry: g } = graphic as any;

      switch (g.type) {
        case 'point':
          const point = new Point({
            x: g.x,
            y: g.y,
            spatialReference: NSWLambert,
          });

          const screenPoint = view.toScreen(point);

          // 3 pixel buffer around point
          const extent = {
            xmin: screenPoint.x - 3,
            ymin: screenPoint.y + 3,
            xmax: screenPoint.x + 3,
            ymax: screenPoint.y - 3,
          };

          const tl = { x: extent.xmin, y: extent.ymax };
          const br = { x: extent.xmax, y: extent.ymin };

          const point1 = view.toMap(tl);
          const point2 = view.toMap(br);

          setGeometry({
            geometryString: JSON.stringify({ 'xmin': point1.x, 'ymin': point2.y, 'xmax': point2.x, 'ymax': point1.y, 'spatialReference': { 'wkid': NSWLambert.wkid } }),
            geometryType: 'esriGeometryEnvelope',
          });
          break;
        case 'polygon':
          setGeometry({
            geometryString: JSON.stringify({ rings: g.rings }),
            geometryType: 'esriGeometryPolygon',
          });
          break;
        case 'polyline':
          setGeometry({
            geometryString: JSON.stringify({ paths: g.paths }),
            geometryType: 'esriGeometryPolyline',
          });
          break;
        default:
          console.log('Unsupported type:', g.type);
      };

      setDrawType(undefined);
      setGraphic(undefined);
    }

  }, [graphic]);

  const drawTypes: any = [
    { type: 'point', icon: 'esri-icon-locate' },
    { type: 'circle', icon: 'esri-icon-plus-circled' },
    { type: 'polyline', icon: 'esri-icon-polyline' },
    { type: 'rectangle', icon: 'esri-icon-add-attachment' },
    { type: 'polygon', icon: 'esri-icon-polygon' },
  ];

  const updateGraphics = () => {
    const baseURL = queryConfig[config].endpoints.layer;

    const params: any = {
      f: 'json',
      geometry: geometry.geometryString,
      geometryType: geometry.geometryType,
      returnGeometry: true,
      spatialRel: 'esriSpatialRelIntersects',
      maxAllowableOffset: 10,
      outFields: queryConfig[config].fields.map((e: any) => e.key).join(','),
      siteId: 'overview',
      inSR: NSWLambert,
      outSR: NSWLambert,
    };

    request(baseURL, params, (response: any) => {
      findRequestCallback(response, queryConfig[config], props);
      setGraphic(undefined);
    }, undefined, undefined);
  };

  return (
    <Fragment>
      {drawTypes.map((dt: any) => {
        return (
          <IconButton onClick={() => drawType === dt.type ? setDrawType(undefined) : setDrawType(dt.type)}>
            <div
              style={{ background: drawType === dt.type ? '#9fa8da' : 'white', borderRadius: 2 }}
              role='button'
              tabIndex={0}
              className='esri-home esri-widget--button esri-widget'
            >
              <span className={`esri-icon ${dt.icon}`} aria-hidden='true' />
            </div>
          </IconButton>
        );
      })}
      <SelectComponent
        value={layer}
        setValue={setLayer}
        label='Select a Layer'
      >
        <MenuItem value={'All Metro Bus Routes'}>All Metro Bus Routes</MenuItem>
        <MenuItem value={'Bus Stops'}>Bus Stops</MenuItem>
        <MenuItem value={'Ferry Routes'}>Ferry Routes</MenuItem>
        {/* <MenuItem value={'Ferry Wharf'}>Ferry Wharf</MenuItem> */}
        <MenuItem value={'Metro Bus Interchange'}>Metro Bus Interchange</MenuItem>
        <MenuItem value={'OPAL Retailers'}>OPAL Retailers</MenuItem>
        <MenuItem value={'Rail Stations'}>Rail Stations</MenuItem>
        <MenuItem value={'Regional Coaches'}>Regional Coaches</MenuItem>
        <MenuItem value={'Rural Contract A Routes'}>Rural Contract A Routes</MenuItem>
        <MenuItem value={'Rural Line of Route'}>Rural Line of Route</MenuItem>
        <MenuItem value={'Rural Service Region'}>Rural Service Region</MenuItem>
      </SelectComponent>
    </Fragment>
  );
};

export default Spatial;