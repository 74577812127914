import * as urlUtils from '@arcgis/core/core/urlUtils';
import Geometry from '@arcgis/core/geometry/Geometry';
import * as projection from '@arcgis/core/geometry/projection';
import Extent from '@arcgis/core/geometry/Extent';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import MapView from '@arcgis/core/views/MapView';
import MenuItem from '@material-ui/core/MenuItem';

// TODO: Refactor out geometry/GIS code out to separate utils.
// TODO: Make strings such as geometry type constants/enums.
// Spatial References
export const NSWLambert = new SpatialReference({ wkid: 3308 });
export const GDA1994 = new SpatialReference({ wkid: 4283 });
export const WGS1984 = new SpatialReference({ wkid: 4326 });
export const MGAZone54 = new SpatialReference({ wkid: 28354 });
export const MGAZone55 = new SpatialReference({ wkid: 28355 });
export const MGAZone56 = new SpatialReference({ wkid: 28356 });
export const WebMercator = new SpatialReference({ wkid: 102100 });

let controller = new AbortController();
let signal = controller.signal;

// Takes a geometry and a geometry type and transforms the geometry into a usable structure
export const createGeometry = (geometry: any, geometryType: string, spatialReference?: number) => {
    if (geometryType === 'esriGeometryPoint') {
        const inputSpatialReference = spatialReference ?? geometry.spatialReference;
        if (inputSpatialReference === WGS1984.wkid) {
            return {
                type: 'point',
                longitude: geometry.x,
                latitude: geometry.y,
                spatialReference: WGS1984,
            };
        } else {
            return {
                type: 'point',
                x: geometry.x,
                y: geometry.y,
                spatialReference: spatialReference ?? geometry.spatialReference,
            };
        }
    }

    if (geometryType === 'esriGeometryPolygon') {
        return {
            type: 'polygon',
            rings: geometry.rings,
            spatialReference: spatialReference ?? geometry.spatialReference,
        };
    }

    if (geometryType === 'esriGeometryPolyline') {
        return {
            type: 'polyline',
            paths: geometry.paths,
            spatialReference: spatialReference ?? geometry.spatialReference,
        };
    }
};

const getURL = (baseURL: string, params: any) => {
    return `${baseURL}?${Object.keys(params).map((param: any) => `${param}=${params[param]}`).join('&')}`
};

export const request = (baseURL: string, params: any, successCallback: Function, errorCallback: Function | null | undefined, signal: AbortSignal | null | undefined) => {
    const url = getURL(baseURL, params);

    fetch(encodeURI(url).replace(/'/g, '%27'), { signal: signal })
        .then(async (response) => {
            successCallback(response);
        })
        .catch((error: any) => {
            if (errorCallback)
                errorCallback(error);
        });
};

// Adds widget to view/container
export const addWidget = (view: MapView, widget: any, position: any, container?: any) => {
    if (container) {
        container.appendChild(widget);
        return;
    }
    view.ui.add(widget, position);
};

// Removes widget from view/container
export const removeWidget = (view: MapView, widget: any, container?: any) => {
    if (container) {
        if (container.contains(widget)) {
            container.removeChild(widget);
        }
        return;
    }
    view.ui.remove(widget);
};

const maxHistory = 50;

/**
 * In-memory history cache
 */
const history: Extent[] = [];

/**
 * In-memory future cache
 */
const future: Extent[] = [];

/**
 * Will empty the future list
 */
const clearFuture = () => {
    future.splice(0, future.length);
};

/**
 * Will add the provided extent to history and call `clearFuture`.
 * If the maximum history has been achieved, the history list will be shifted.
 * @param extent The extent to add to history
 */
export const pushHistory = (extent: Extent, keepFuture?: boolean) => {
    if (extent) {
        history.push(extent);
        if (history.length > maxHistory) {
            history.shift();
        }
        if (!keepFuture) clearFuture();
    }
};

/**
 * Will return the most recent Extent or null if none exist
 */
export const popHistory = (): Extent | null => history.pop() ?? null;

/**
 * Will add the provided extent to future.
 * If the maximum future has been achieved, the future list will be shifted.
 * @param extent The extent to add to future
 */
export const pushFuture = (extent: Extent) => {
    if (extent) {
        future.push(extent);
        if (future.length > maxHistory) {
            future.shift();
        }
    }
};

/**
 * Will return the most recent future Extent or null if none exist
 */
export const popFuture = (): Extent | null => future.pop() ?? null;

export interface ActionButton {
    icon: string;
    title: string;
    onClick: Function;
};

export interface ToggleButton {
    icon: string;
    title: string;
    onActivate?: Function;
    onDeactivate?: Function;
};

export const addGeohubProxyUrls = () => {
    urlUtils.addProxyRule({
        urlPrefix: 'https://geohub.transport.nsw.gov.au/server/rest/services/Hosted/Transport_Map_Grayscale/VectorTileServer',
        proxyUrl: 'http://gis-dev.transport.nsw.gov.au/geohubProxy/proxy.jsp',
    });

    urlUtils.addProxyRule({
        urlPrefix: 'https://geohub.transport.nsw.gov.au/server/rest/services/Hosted/Transport_Map_Colour/VectorTileServer',
        proxyUrl: 'http://gis-dev.transport.nsw.gov.au/geohubProxy/proxy.jsp',
    });
};

export const updateMenuItems = (baseURL: string, searchField: string, inputValue: string, inputTarget: any, setMenuItems: any, updateGraphics: any) => {
    controller.abort();
    controller = new AbortController();
    signal = controller.signal;

    const params: any = {
        start: 0,
        count: 25,
        searchField: searchField
    };

    params[searchField] = inputValue;

    const callback = async (response: any) => {
        const data = await response.json();
        const items = data.items.slice(0, 5);

        if (items.length === 0) {
            setMenuItems(<MenuItem>Not Found</MenuItem>);
            return;
        }

        setMenuItems(items.map((item: any) => {
            return (
                <MenuItem onClick={() => {
                    updateGraphics(item);
                    setMenuItems(null);

                    if (inputTarget) {
                        inputTarget.value = item[searchField];
                    }
                }}>
                    {item[searchField]}
                </MenuItem >
            );
        }));
    };

    request(baseURL, params, callback, undefined, signal);
};

export const getStaticMenuItems = (searchField: any, baseURL: string, setMenuData: any) => {
    const params: any = {
        count: 1000,
        searchField: searchField,
    };

    const callback = async (response: any) => {
        const data = await response.json();
        setMenuData(data.items);
    };

    request(baseURL, params, callback, undefined, undefined);
};

export const filterStaticMenuItems = (searchField: any, inputValue: any, inputTarget: any, menuData: any, setMenuItems: any, updateGraphics: any) => {
    const filteredMenuData = menuData.filter((item: any) => {
        return item[searchField].toLowerCase().includes(inputValue.toLowerCase());
    });

    setMenuItems(filteredMenuData.slice(0, 5).map((item: any) => {
        return (
            <MenuItem onClick={() => {
                updateGraphics(item);
                setMenuItems(null);

                if (inputTarget) {
                    inputTarget.value = item[searchField];
                }
            }}>
                {item[searchField]}
            </MenuItem>
        );
    }));
};

export const findRequestCallback = async (response: any, queryConfig: any, props: any) => {
    const { setResults, setDetailedResults, setData } = props;

    const data = await response.json();

    setData(data);

    setResults(data.features.map((feature: any) => {
        const keys = queryConfig.fields.map((e: any) => e.key);
        const filteredKeys = keys.filter((key: string) => queryConfig.results.includes(key));
        return filteredKeys.map((key: string) => feature.attributes[key]);
    }));

    setDetailedResults(data.features.map((feature: any) => {
        const detailedResults: any = {};

        queryConfig.fields.forEach((field: any) => {
            detailedResults[field.label] = feature.attributes[field.key];
        });

        return detailedResults;
    }));
};

// TODO: Make the assigned variable names consistent e.g. "nswLambertPoint".
export const projectToNSWLambert = (geometry: Geometry) => {
    const convertedGeometry: Geometry = projection.project(geometry, NSWLambert) as Geometry;

    return convertedGeometry;
};

// TODO: Make the assigned variable names consistent e.g. "mapPoint" or "webMercatorPoint".
export const projectToWebMercator = (geometry: Geometry) => {
    const convertedGeometry: Geometry = projection.project(geometry, WebMercator) as Geometry;

    return convertedGeometry;
}