import Extent from '@arcgis/core/geometry/Extent';
import { initialExtent } from 'config';
import { ActionButton } from 'utils';
import ActionWidget from './ActionWidget';

const FullExtentWidget = (props: any) => {
  const { view } = props;
  const title = 'Full Extent';

  const button: ActionButton = {
    icon: 'esri-icon-globe',
    title: title,
    onClick: () => {
      const extent = new Extent(initialExtent);
      view.goTo(extent);
    },
  };

  return (<ActionWidget button={button} />);
}

export default FullExtentWidget;
