import indigo from '@material-ui/core/colors/indigo';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    titlebar: {
        width: '100%',
        height: '1.75rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textTransform: 'uppercase',
        letterSpacing: '0.2rem',
        borderBottom: '1px solid #ccc',
        backgroundColor: indigo[200],
        color: '#eee',
        padding: '4px 0',
    },
    titlebarClose: {
        cursor: 'pointer',
        paddingRight: '0.5rem',
    },
});

/**
 *  Returns a consistent header
 */
const TitlebarComponent = (props: any) => {
    const classes = useStyles();
    const { title, onTitleClose } = props;

    if (!title) {
        return null;
    }

    const onClose = () => {
        if (onTitleClose) {
            onTitleClose();
        }
    };

    return (
        <div className={`${classes.titlebar} draggable`}>
            <span></span>
            {title}
            <span className={`${classes.titlebarClose} esri-icon-close`} onClick={onClose}></span>
        </div>
    );
};

export default TitlebarComponent;