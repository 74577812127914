import { useState } from 'react';
import { ToggleButton } from 'utils';
import { getBasemapGallery } from 'utils/basemapgallery';
import esriContainer from './EsriContainer';
import ToggleWidget from './ToggleWidget';

const BasemapsWidget = (props: any) => {
  const { view } = props;
  const [active, setActive] = useState(false);
  const title = 'Basemaps';

  const [basemapsWidget] = useState(getBasemapGallery(view, esriContainer(title, setActive)));

  const button: ToggleButton = {
    icon: 'esri-icon-basemap',
    title: title,
  };

  // TODO: Need to make Esri-based widgets draggable, similar to all other non-Esri-based widgets.
  return (<ToggleWidget {...props} widget={basemapsWidget} button={button} active={active} setActive={setActive} />);
}

export default BasemapsWidget;
