/* eslint-disable react-hooks/exhaustive-deps */
import MenuItem from '@material-ui/core/MenuItem';
import DropDownComponent from 'components/Dropdown';
import InputComponent from 'components/Input';
import SelectComponent from 'components/Select';
import { query } from 'config';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { request } from 'utils';
import { filterStaticMenuItems, findRequestCallback, getStaticMenuItems, NSWLambert } from 'utils';

const FindSydneyTrains = (props: any) => {
    const [menuItems, setMenuItems] = useState<ReactElement | null>(null);
    const [menuData, setMenuData] = useState<any>(null);
    const [inputTarget, setInputTarget] = useState<any>(undefined);
    const [inputValue, setInputValue] = useState<any>(undefined);
    const [inputIndex, setInputIndex] = useState(0);
    const queryConfig = query.find.sydneyTrains;

    useEffect(() => {
        getStaticMenuItems(queryConfig.searchFields[0], queryConfig.endpoints.searches[0], setMenuData);
    }, []);

    useEffect(() => {
        setMenuData(null);
        getStaticMenuItems(queryConfig.searchFields[inputIndex], queryConfig.endpoints.searches[inputIndex], setMenuData);
    }, [inputIndex]);

    useEffect(() => {
        if (inputValue && menuData) {
            filterStaticMenuItems(queryConfig.searchFields[inputIndex], inputValue, inputTarget, menuData, setMenuItems, updateGraphics);
        }
    }, [inputValue, menuData]);

    const updateGraphics = (item: any) => {
        const baseURL = queryConfig.endpoints.layer;
        const value = item[queryConfig.searchFields[inputIndex]];

        const params: any = {
            f: 'json',
            where: `UPPER(${queryConfig.searchFields[inputIndex]}) = '${value.toUpperCase().replace('(', '%_%').replace(')', '%_%')}'`,
            returnGeometry: true,
            spatialRel: 'esriSpatialRelIntersects',
            maxAllowableOffset: 10,
            outFields: queryConfig.fields.map((e: any) => e.key).join(','),
            siteId: 'overview',
            outSR: NSWLambert,
        };

        request(baseURL, params, (response: any) => findRequestCallback(response, queryConfig, props), undefined, undefined);
    };

    return (
        <Fragment>
            <SelectComponent
                value={inputIndex}
                label='Select a Query'
                setValue={setInputIndex}
            >
                <MenuItem value={0}>Find a Sydney Train Line by Line Name</MenuItem>
                <MenuItem value={1}>Find a Sydney Train Line by Section Name</MenuItem>
            </SelectComponent>
            {inputIndex === 0 && <InputComponent
                label='Line Name'
                placeholder='(e.g Bankstown Line)'
                inputTarget={inputTarget}
                setInputTarget={setInputTarget}
                setInputValue={setInputValue}
            />}
            {inputIndex === 1 && <InputComponent
                label='Section Name'
                placeholder='(e.g Central to Redfern)'
                inputTarget={inputTarget}
                setInputTarget={setInputTarget}
                setInputValue={setInputValue}
            />}
            <DropDownComponent>{menuItems}</DropDownComponent>
        </Fragment>
    );
};

export default FindSydneyTrains;