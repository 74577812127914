import { useState } from 'react';
import { ToggleButton } from 'utils';
import ToggleWidget from './ToggleWidget';

const ShareWidget = (props: any) => {
  const [active, setActive] = useState(false);
  const title = 'Share';

  const button: ToggleButton = {
    icon: 'esri-icon-share2',
    title: title,
  };

  return (<ToggleWidget {...props} button={button} active={active} setActive={setActive} />);
}

export default ShareWidget;
