/* eslint-disable react-hooks/exhaustive-deps */
import TileInfo from '@arcgis/core/layers/support/TileInfo';
import Map from '@arcgis/core/Map';
import ESRIMapView from '@arcgis/core/views/MapView';
import { initialExtent } from 'config';
import { createContext, useEffect, useState } from 'react';
import { OSMBasemap } from 'utils/basemapgallery';

const map: Map = new Map({
    basemap: OSMBasemap,
});

const view = new ESRIMapView({
    container: 'viewDiv',
    map,
    constraints: {
        lods: TileInfo.create().lods,
        minScale: 10000000,
    },
    extent: initialExtent,
    ui: {
        components: ['attribution'],
    },
});

/**
 * Assigns the container element to the View
 * @param container
 */
const initialize = (container: HTMLDivElement) => {
    view.container = container;
    view.when()
        .then(() => {
            console.log('Map and View are ready');
        })
        .catch((error: any) => {
            console.warn('An error in creating the map occured:', error);
        });
};

export interface AppProviderProps {
    children: JSX.Element | JSX.Element[];
}

interface ContextProps {
    container: HTMLDivElement | null;
    setContainer: (a: HTMLDivElement) => void;
    map: Map,
    view: ESRIMapView,
}

/**
 * Main application context
 */
const MapContext = createContext<ContextProps>({
    container: null,
    setContainer: () => { },
    map: map,
    view: view,
});
/**
 * Main application provider
 */
export const MapContextProvider = (props: any) => {
    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    // When container is ready, we can load the mapping portion of our application and initialize it
    const loadMap = async () => {
        if (container) {
            initialize(container);
        }
    };

    useEffect(() => {
        loadMap();
    }, [container]);

    const value = {
        container,
        setContainer,
        map,
        view,
    };

    // This provider allows the children to have access to the value property. 
    // All children in this case have access to container, setContainer, map, and view
    return <MapContext.Provider value={value}>
        {props.children}
    </MapContext.Provider>;
};

export default MapContext;
