import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Fragment, useState } from 'react';
import { ActionButton } from 'utils';
import ActionWidget from './ActionWidget';

const AboutWidget = (props: any) => {
  const [open, setOpen] = useState(false);
  const title = 'About';

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const button: ActionButton = {
    icon: 'esri-icon-lightbulb',
    title: title,
    onClick: handleClickOpen,
  };

  return (
    <Fragment>
      <ActionWidget button={button} />
      <AboutDialog open={open} onClose={handleClose} />
    </Fragment>
  );
}

const AboutDialog = (props: any) => {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const links = [
    {
      label: 'NSW Government',
      href: 'http://www.nsw.gov.au/',
    },
    {
      label: 'Accessibility',
      href: 'http://www.transport.nsw.gov.au/website-accessibility',
    },
    {
      label: 'Copyright and Disclaimer',
      href: 'http://www.transport.nsw.gov.au/copyright-and-disclaimer',
    },
    {
      label: 'Privacy',
      href: 'http://www.transport.nsw.gov.au/online-privacy',
    },
  ]

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle >{'About'}</DialogTitle>
      <DialogContent>
        <h1>TransPortal</h1>
        <div>TransPortal is a web based geospatial application provided by the Spatial Systems Group, Group Information & Technology, TfNSW.</div>
        <h2>Feedback</h2>
        <div>If you have feedback regarding <span>TransPortal</span> or questions regarding the functionality or the data displayed in <span>TransPortal</span> please contact the <span onClick={() => 'parent.location="mailto:transportal@transport.nsw.gov.au"'}>TransPortal Administrator</span>.</div>
        <h2>Support</h2>
        <div><span>TransPortal</span> is designed to work with modern browsers such as Firefox, Google Chrome, or Internet Explorer 8 and above. Please note <span>TransPortal</span> is not supported on Internet Explorer below version 8. Please contact the <span onClick={() => 'window.open("https://myservices.transport.nsw.gov.au:8443/ux/myitapp/#/catalog/home","_blank","channelmode=yes,resizable=yes")'}>MyIT self service portal</span> if you do not have an appropriate browser installed on your computer or have problems displaying <span>TransPortal</span>.
        </div>
        <h2>
          Data Disclaimer
        </h2>
        <div>Please be aware that the data and information provided through this application are either collected by Transport for NSW or supplied by external and commercial data providers under various licensing agreements. These data may not be accurate, current or complete and are subject to change without notice.</div>
        <br />
        <div>Transport for NSW grants you licence to view and print a copy of the material on the <span>TransPortal</span> application solely for the use of Transport for NSW staff and third parties engaged on behalf of Transport for NSW. Except for the use of facilities provided through this application you may not copy, modify, distribute, transmit, display, reproduce, publish, license, create derivative works from, frame in another website, transfer or sell the material provided through this application.</div>
        <br />
        <div>Transport for NSW is not responsible for the maintenance or operation of any third party website or service linked to from this site or the validity and accuracy of any material contained on that site or service.</div>
        <br />
        <div>
          <span>
            {links.map((link, index) => {
              return (<span key={index}><a href={link.href}>{link.label}</a>&nbsp;&nbsp;</span>);
            })}
          </span>
        </div>
        <div>© Transport for NSW 2013</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary' autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AboutWidget;