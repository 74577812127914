/* eslint-disable react-hooks/exhaustive-deps */
import Point from '@arcgis/core/geometry/Point';
import { query } from 'config';
import { addGraphic, createGraphic } from 'graphics';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import DropDownComponent from '../Dropdown';
import { updateMenuItems, projectToWebMercator } from 'utils';
import InputComponent from '../Input';

const GoToAddress = (props: any) => {
  const { view } = props;
  const [menuItems, setMenuItems] = useState<ReactElement | null>(null);
  const [inputTarget, setInputTarget] = useState<any>(undefined);
  const [inputValue, setInputValue] = useState<any>(undefined);
  const queryConfig = query.goto.address;

  useEffect(() => {
    if (inputValue) {
      updateMenuItems(queryConfig.endpoints.search, queryConfig.searchField, inputValue, inputTarget, setMenuItems, updateGraphics);
    } else {
      setMenuItems(null);
    }
  }, [inputValue]);

  const updateGraphics = (item: any) => {
    const value = item[queryConfig.searchField];

    const point = new Point(item);
    const convertedPoint = projectToWebMercator(point);
    const graphic = createGraphic(convertedPoint, value);

    addGraphic(view, graphic, true);

    view.goTo({ center: graphic?.geometry, zoom: 15 });
  };

  return (
    <Fragment>
      <InputComponent
        label='Address'
        placeholder='(e.g 100 George St Sydney)'
        inputTarget={inputTarget}
        setInputTarget={setInputTarget}
        setInputValue={setInputValue}
      />
      <DropDownComponent>{menuItems}</DropDownComponent>
    </Fragment>
  );
};

export default GoToAddress;