import { makeStyles } from '@material-ui/core/styles';
import { Children, cloneElement, Fragment, FunctionComponent } from 'react';

const useStyles = makeStyles({
    toolbar: {
        display: 'grid',
        gridAutoFlow: 'column',
    }
});

/**
 * This groups the toolbar groups
 */
const Toolbar: FunctionComponent<any> = (props: any) => {
    const classes = useStyles();
    const { view, map, position, container } = props;

    return (
        <div
            className={classes.toolbar}
        >
            {Children.map(props.children, (child: any, index: number) => {
                // Each child is a toolbar group. Here the child is cloned so that the properties can be passed to it
                return (
                    <Fragment>
                        {cloneElement(child, { view, map, position, container })}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default Toolbar;
