
import Draggable from 'react-draggable';

/**
 * Provides a draggable container
 */
const DraggableWidget = (props: any) => {
    const { children, handle } = props;

    // The handle is the element that is used to drag the container
    return (
        <Draggable handle={handle}>
            <div>
                {children}
            </div>
        </Draggable>
    );
};

export default DraggableWidget;