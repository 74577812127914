/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core/styles';
import FullExtentWidget from 'components/FullExtent';
import HistoryManagerWidget from 'components/HistoryManager';
import Toolbar from 'components/Toolbar';
import ToolbarGroup from 'components/ToolbarGroup';
import ZoomInWidget from 'components/ZoomIn';
import ZoomOutWidget from 'components/ZoomOut';
import { ToolbarContextProvider } from 'contexts/toolbar-context';
import { useEffect } from 'react';




export const topCenter = document.createElement('div');

const useStyles = makeStyles({
    widget: {
        marginBottom: 10,
        pointerEvents: 'auto',
    },
    topCenter: {
        top: '16px',
        left: '50%',
        transform: 'translateX(-50%)',
        alignItems: 'center',
        flexFlow: 'column',
        position: 'absolute',
        display: 'flex',
        pointerEvents: 'none !important' as 'none',
    },
});

const TopCenter = (props: any) => {
    const classes = useStyles();
    const { view } = props;

    // Adds top center content to view on first render
    useEffect(() => {
        topCenter.className = classes.topCenter;
        view.ui.add(topCenter);
    }, []);

    return (
        <div className={classes.widget}>
            <ToolbarContextProvider>
                <Toolbar {...props}>
                    <ToolbarGroup>
                        <FullExtentWidget />
                        <HistoryManagerWidget />
                        <ZoomInWidget />
                        <ZoomOutWidget />
                    </ToolbarGroup>
                </Toolbar>
            </ToolbarContextProvider>
        </div>
    );
};

export default TopCenter;