import MapView from '@arcgis/core/views/MapView';
import { useState } from 'react';
import { ToggleButton } from 'utils';
import getSearchDetails from './search';
import ToggleWidget from '../ToggleWidget';

const SearchBoxWidget = (props: any) => {
  const { view } = props;
  const [active, setActive] = useState(false);
  const title = 'Searchbox';

  const [searchBoxWidget] = useState(getSearchDetails(view, null, setActive));

  const button: ToggleButton = {
    icon: 'esri-icon-search',
    title: title,
    onActivate: () => {
    },
    onDeactivate: (view: MapView) => {
    },
  };

  return (<ToggleWidget {...props} widget={searchBoxWidget} button={button} active={active} setActive={setActive} />);
}

export default SearchBoxWidget;
