/* eslint-disable react-hooks/exhaustive-deps */
import { query } from 'config';
import { addNSWLambertGraphicFromResponse } from 'graphics';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { request } from 'utils';
import DropDownComponent from '../Dropdown';
import { updateMenuItems, NSWLambert } from 'utils';
import InputComponent from '../Input';

const GoToLocality = (props: any) => {
  const { view } = props;
  const [menuItems, setMenuItems] = useState<ReactElement | null>(null);
  const [inputTarget, setInputTarget] = useState<any>(undefined);
  const [inputValue, setInputValue] = useState<any>(undefined);
  const queryConfig = query.goto.locality;

  useEffect(() => {
    if (inputValue) {
      updateMenuItems(queryConfig.endpoints.search, queryConfig.searchField, inputValue, inputTarget, setMenuItems, updateGraphics);
    } else {
      setMenuItems(null);
    }
  }, [inputValue]);

  const updateGraphics = (item: any) => {
    const locality = item.locality;
    const type = locality.match(/\((.*?)\)/)?.[1];

    let baseURL;

    switch (type) {
      case 'LGA':
        baseURL = queryConfig.endpoints.layers[0];
        break;
      case 'SUBURB':
        baseURL = queryConfig.endpoints.layers[1];
        break;
      case 'LOCALITY':
        baseURL = queryConfig.endpoints.layers[2];
        break;
      default:
        break;
    }

    if (!baseURL) {
      return;
    }

    const value = item[queryConfig.searchField];
    const objectid = item.objectid.split(':')[0];

    const params: any = {
      f: 'json',
      where: `OBJECTID=${objectid}`,
      returnGeometry: true,
      spatialRel: 'esriSpatialRelIntersects',
      siteId: 'overview',
      outSR: NSWLambert,
    };

    request(baseURL, params, (response: any) => addNSWLambertGraphicFromResponse(view, response, value), undefined, undefined);
  };

  return (
    <Fragment>
      <InputComponent

        label='Locality Name'
        placeholder='(e.g Sydney)'
        inputTarget={inputTarget}
        setInputTarget={setInputTarget}
        setInputValue={setInputValue}
      />
      <DropDownComponent>{menuItems}</DropDownComponent>
    </Fragment>
  );
};

export default GoToLocality;