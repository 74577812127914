/* eslint-disable react-hooks/exhaustive-deps */
import { query } from 'config';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { request } from 'utils';
import DropDownComponent from '../Dropdown';
import InputComponent from '../Input';
import { findRequestCallback, updateMenuItems, NSWLambert } from 'utils';

const FindMetroBusInterchange = (props: any) => {
    const [menuItems, setMenuItems] = useState<ReactElement | null>(null);
    const [inputTarget, setInputTarget] = useState<any>(undefined);
    const [inputValue, setInputValue] = useState<any>(undefined);
    const queryConfig = query.find.metroBusInterchange;

    useEffect(() => {
        if (inputValue) {
            updateMenuItems(queryConfig.endpoints.search, queryConfig.searchField, inputValue, inputTarget, setMenuItems, updateGraphics);
        } else {
            setMenuItems(null);
        }
    }, [inputValue]);

    const updateGraphics = (item: any) => {
        const baseURL = queryConfig.endpoints.layer;
        const value = item[queryConfig.searchField];

        const params: any = {
            f: 'json',
            where: `UPPER(${queryConfig.searchField}) = '${value.toUpperCase().replace('(', '%_%').replace(')', '%_%')}'`,
            returnGeometry: true,
            spatialRel: 'esriSpatialRelIntersects',
            maxAllowableOffset: 10,
            outFields: queryConfig.fields.map((e: any) => e.key).join(','),
            siteId: 'overview',
            outSR: NSWLambert,
        };

        request(baseURL, params, (response: any) => findRequestCallback(response, queryConfig, props), undefined, undefined);
    };

    return (
        <Fragment>
            <InputComponent

                label='Name'
                placeholder='(e.g Martin Place)'
                inputTarget={inputTarget}
                setInputTarget={setInputTarget}
                setInputValue={setInputValue}
            />
            <DropDownComponent>{menuItems}</DropDownComponent>
        </Fragment>
    );
};

export default FindMetroBusInterchange;