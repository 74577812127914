import MenuItem from '@material-ui/core/MenuItem';
import GoToAddress from 'components/GoTo/GoToAddress';
import GoToCoordinate from 'components/GoTo/GoToCoordinate';
import GoToInterchange from 'components/GoTo/GoToInterchange';
import GoToIntersection from 'components/GoTo/GoToIntersection';
import GoToLocality from 'components/GoTo/GoToLocality';
import GoToPostcode from 'components/GoTo/GoToPostcode';
import ToggleWidget from 'components/ToggleWidget';
import { useState } from 'react';
import { ToggleButton } from 'utils';
import DraggableWidget from './DraggableWidget';
import ContainerComponent from './Container';
import SelectComponent from './Select';

const GoToWidget = (props: any) => {
  const { view } = props;
  const [goto, setGoto] = useState('Address');
  const [active, setActive] = useState(false);
  const title = 'GoTo';

  const html = (
    <DraggableWidget handle={'.draggable'}>
      <ContainerComponent title={title} onTitleClose={() => setActive(false)}>
        <SelectComponent label={'GoTo'} value={goto} setValue={setGoto}>
          {/* <MenuItem value={'All'}>All</MenuItem> */}
          <MenuItem value={'Address'}>Address</MenuItem>
          <MenuItem value={'Coordinate'}>Coordinate</MenuItem>
          <MenuItem value={'Interchange'}>Interchange</MenuItem>
          <MenuItem value={'Intersection'}>Intersection</MenuItem>
          <MenuItem value={'Locality'}>Locality</MenuItem>
          <MenuItem value={'Postcode'}>Postcode</MenuItem>
        </SelectComponent>
        {/* {goto === 'All' && <GoToAll view={view} />} */}
        {goto === 'Address' && <GoToAddress view={view} />}
        {goto === 'Coordinate' && <GoToCoordinate view={view} />}
        {goto === 'Interchange' && <GoToInterchange view={view} />}
        {goto === 'Intersection' && <GoToIntersection view={view} />}
        {goto === 'Locality' && <GoToLocality view={view} />}
        {goto === 'Postcode' && <GoToPostcode view={view} />}
        <p>
          Please select one of the search options from the dropdown list above.
        </p>
      </ContainerComponent>
    </DraggableWidget>
  );

  const button: ToggleButton = {
    icon: 'esri-icon-navigation',
    title: title,
    onDeactivate: () => view.graphics.removeAll(),
  };

  // TODO: Clear geometry on clearing the input?
  return (
    <ToggleWidget {...props} html={html} button={button} active={active} setActive={setActive} />);
}

export default GoToWidget;
