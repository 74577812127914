import indigo from '@material-ui/core/colors/indigo';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import overviewTheme from '../theme';
import TitlebarComponent from './Titlebar';


const useStyles = makeStyles({
    container: {
        fontSize: 12,
        backgroundColor: '#fff',
        color: '#6e6e6e',
        boxShadow: '0 1px 2px rgb(0 0 0 / 30%)',
        marginBottom: 10,
    },
    content: {
        padding: '3px 6px',
    },
    titlebar: {
        width: '100%',
        height: '1.75rem',

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        textTransform: 'uppercase',
        letterSpacing: '0.2rem',

        borderBottom: '1px solid #ccc',

        backgroundColor: indigo[200],
        color: '#eee',
    },
    titlebarClose: {
        cursor: 'pointer',
        paddingRight: '0.5rem',
    }
});

// TODO: Refactor low-level widgets such as container/esri-container etc. to other folders.
/**
 *  Returns a consistent container that resembles the esri widget containers
 */
const ContainerComponent = (props: any) => {
    const classes = useStyles();
    const { children, title, onTitleClose, style } = props;

    return (
        <ThemeProvider theme={overviewTheme}>
            <div className={classes.container} style={style || {}}>
                {/* Titlebar here. It will not show if no title is specified. It is draggable by default if there's a DraggableWidget wrapping around it. */}
                <TitlebarComponent title={title} onTitleClose={onTitleClose} />
                {/* Actual content of the container. */}
                <div className={classes.content}>
                    {children}
                </div>
            </div>
        </ThemeProvider>
    );
};

export default ContainerComponent;