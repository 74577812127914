/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

export const bottomCenter = document.createElement('div');

const useStyles = makeStyles({
    bottomCenter: {
        bottom: '30px',
        left: '50%',
        transform: 'translateX(-50%)',
        alignItems: 'center',
        flexFlow: 'column',
        position: 'absolute',
        display: 'flex',
        pointerEvents: 'none !important' as 'none',
    },
});

const BottomCenter = (props: any) => {
    const classes = useStyles();
    const { view } = props;

    // Adds bottom center content to view on first render
    useEffect(() => {
        bottomCenter.className = classes.bottomCenter;
        view.ui.add(bottomCenter);
    }, []);

    return null;
};

export default BottomCenter;